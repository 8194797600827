import { useWindowSize } from '@common/../lib-components/Carousel';
import { SliderSliceType } from '@common/store/slider/slice';
import { getLambdaLink, isMobile } from '@common/utils';
import { LAYOUT_CONFIG } from '@theme/utils/constants';
interface Slider {
  slider: number;
  image: string;
  heading: string;
  subheading: string;
  link: string;
  type: string;
  label?: string;
  showCustomText: boolean;
  showButton: boolean;
  isVisible?: boolean;
}

export const sliderDimensions = {
  HB1: { desktop: { width: 608, height: 436 }, mobile: { width: 621, height: 562 } },
  HB2: { desktop: { width: 1440, height: 554 }, mobile: { width: 621, height: 424 } },
  HB3: { desktop: { width: 1440, height: 598 }, mobile: { width: 621, height: 525 } },
  HB4: { desktop: { width: 1440, height: 509 }, mobile: { width: 621, height: 526 } },
  HB5: {
    desktop: { width: LAYOUT_CONFIG?.HB5?.desktop?.width || 1440, height: LAYOUT_CONFIG?.HB5?.desktop?.height || 650 },
    mobile: { width: LAYOUT_CONFIG?.HB5?.mobile?.width || 621, height: LAYOUT_CONFIG?.HB5?.mobile?.height || 562 },
  },
  HB6: { desktop: { width: 1440, height: 652 }, mobile: { width: 621, height: 642 } },
  HB7: { desktop: { width: 1440, height: 563 }, mobile: { width: 621, height: 482 } },
  HB8: { desktop: { width: 1210, height: 731 }, mobile: { width: 621, height: 375 } },
  HB9: { desktop: { width: 1459, height: 659 }, mobile: { width: 621, height: 315 } },
  HB10: { desktop: { width: 1459, height: 342 }, mobile: { width: 510, height: 120 } },
  HB11: { desktop: { width: 366, height: 206 }, mobile: { width: 165, height: 87 } },
};

export const processResponse = (slider: Record<string, unknown>, layoutName: string): Slider => {
  const { desktop, mobile } = sliderDimensions[layoutName] || {
    mobile: { width: 0, height: 0 },
    desktop: { width: 0, height: 0 },
  };
  return {
    slider: slider.id as number,
    image: slider.image ? getLambdaLink({ link: slider.image as string, ...(isMobile() ? mobile : desktop) }) : null,
    heading: slider.show_custom_text ? (slider.heading as string) : '',
    subheading: slider.show_custom_text ? (slider.sub_heading as string) : '',
    link: slider.show_button ? (slider.button_link as string) : '',
    type: slider.slider_type as string,
    label: slider.show_button ? ((slider.button_label || 'Shop Now') as string) : '',
    showCustomText: slider.show_custom_text as boolean,
    showButton: slider.show_button as boolean,
    isVisible: slider.is_visible as boolean,
  };
};

export default Slider;

export const getFilteredSliderImages = ({
  slider,
  shouldImageBePresent,
}: {
  slider: SliderSliceType;
  shouldImageBePresent?: boolean;
}): Slider[] => {
  let filteredImages = slider.slider?.filter(
    (item) => (item?.image || item?.heading || item?.subheading) && !item?.delete && item?.isVisible,
  );
  const { width } = useWindowSize();
  if (width <= 768) {
    filteredImages = filteredImages?.filter((item) => item?.type != 2);
  } else {
    filteredImages = filteredImages?.filter((item) => item?.type != 3);
  }

  if (shouldImageBePresent) {
    filteredImages = filteredImages?.filter((item) => item?.image);
  }
  return filteredImages;
};
