import Reward from '@common/components/Reward';
import { RootState } from '@common/store';
import {
  addProductToCart,
  CartSliceType,
  removeProductFromCart,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  CategoriesSliceType,
  fetchCategoriesRequest,
  getCategoryQueryParamsInitialState,
} from '@common/store/categories/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { fetchHomeRequest, HomeSliceType } from '@common/store/home/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
  resetProductsStore,
} from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import {
  fetchReferralCodeDetailsRequest,
  ReferralSliceType,
  resetReferralCodeDetails,
} from '@common/store/referral/slice';
import { MetaTagsSliceType } from '@common/store/seo/metaTags/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { TestimonialsSliceType } from '@common/store/testimonials/slice';
import { addToast } from '@common/store/toasts/slice';
import {
  fetchMoreUserSectionsRequest,
  fetchUserSectionsRequest,
  UserSectionsSliceType,
} from '@common/store/userSections/slice';
import { getQueryParams, PRODUCTS_KEY } from '@common/utils/constants';
import PageLayout from '@Core/components/PageLayout';
import CategoryList from '@layouts/CategoryList';
import HtmlBlock from '@layouts/HtmlBlock';
import ImageAndText from '@layouts/ImageAndText';
import ProductList from '@layouts/ProductList';
import RichTextEditor from '@layouts/RichTextEditor';
import Slider from '@layouts/Slider';
import StoreUpdate from '@layouts/StoreUpdate';
import Testimonials from '@layouts/Testimonials';
import TextLayout from '@layouts/TextLayout';
import Error from '@lib/Error';
import InjectScript from '@lib/InjectScript';
import Loading from '@lib/Loading';
import { LAYOUT_CONFIG } from '@theme/utils/constants';
// import classNames from 'classnames';
import React, { PureComponent, RefObject } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { MDPPageType } from '../../../../common/models/MDPAppScript';
import { ERROR_TEXT } from '../../../../common/utils/errorContent';
import { getQueryParamsFromURL } from '../../../../common/utils/params';
import MDPInjectScript from '../../../../lib-components/MDPInjectScript';
import LoadMore from './LoadMore';
import ScrollRef from './ScrollRef';

interface HomeProps {
  products: Record<string, ProductsSliceType>;
  categories: CategoriesSliceType;
  cart: CartSliceType;
  recentlyViewed: RecentlyViewedSliceType;
  home: HomeSliceType;
  userSections: UserSectionsSliceType;
  slider: SliderSliceType;
  storeInfo: StoreInfoSliceType;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchCategoriesRequest: typeof fetchCategoriesRequest;
  fetchHomeRequest: typeof fetchHomeRequest;
  fetchUserSectionsRequest: typeof fetchUserSectionsRequest;
  fetchMoreUserSectionsRequest: typeof fetchMoreUserSectionsRequest;
  resetProductsStore: typeof resetProductsStore;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  addToast: typeof addToast;
  fetchReferralCodeDetailsRequest: typeof fetchReferralCodeDetailsRequest;
  resetReferralCodeDetails: typeof resetReferralCodeDetails;
  features: FeaturesSliceType;
  testimonials: TestimonialsSliceType;
  referral: ReferralSliceType;
  metaTags: MetaTagsSliceType;
}

class Home extends PureComponent<HomeProps> {
  private allProductsRef: RefObject<HTMLDivElement>;
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const queryParam = getQueryParamsFromURL();
    if (queryParam?.referrer_code) {
      this.props.fetchReferralCodeDetailsRequest(queryParam?.referrer_code);
    } else {
      this.props.resetReferralCodeDetails();
    }
    if (!this.props.products[PRODUCTS_KEY.ALL]?.list?.results.length) {
      this.props.resetProductsStore(PRODUCTS_KEY.ALL);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
        },
      });
    }
    if (!this.props.categories.isFetched) {
      this.props.fetchCategoriesRequest({
        queryParams: getCategoryQueryParamsInitialState(),
      });
    }
  }

  fetchMoreUserSections = () => {
    const { fetchMoreUserSectionsRequest, userSections } = this.props;
    if (!userSections.isFetching) {
      fetchMoreUserSectionsRequest();
    }
  };
  render() {
    const { userSections, metaTags } = this.props;
    const queryParams = getQueryParams();
    // const testimonialLayout = this.props.home.sections?.find((section) => section.type === SectionEnum.TESTIMONIAL);

    return (
      <PageLayout>
        {metaTags?.isFetched && !metaTags?.error ? (
          <Helmet>
            <meta name="description" content={metaTags?.metaTags?.description} />
            <meta property="og:description" content={metaTags?.metaTags?.description} />
            <meta name="keywords" content={metaTags?.metaTags?.keywords} />
          </Helmet>
        ) : null}
        <MDPInjectScript pageType={MDPPageType.STORE_PAGE} />
        <InjectScript others="home" />
        {this.props.referral.isReferralCodeFetched && <Reward referral={this.props.referral} />}
        {!userSections.isFetched && <Loading />}
        {userSections.isFetched && userSections.error && <Error message={ERROR_TEXT.HOME} />}
        {userSections.userSections
          .map((section, index) => {
            return (
              <>
                <div id={`${section.id}-scroll`} className="section-scroll"></div>
                {LAYOUT_CONFIG.homepageAnimationEnabled ? <ScrollRef sectionId={section.id} /> : null}
                {(() => {
                  switch (section.sectionType) {
                    case 'Hero Banner':
                      return (
                        <Slider
                          key={section.id}
                          id={section.id}
                          isFirstSection={index === 1}
                          previewQueryLayout={queryParams['HB']}
                        />
                      );

                    case 'Text':
                      return <TextLayout key={section.id} id={section.id} previewQueryLayout={queryParams['Text']} />;

                    case 'Image & Text':
                      return (
                        <ImageAndText key={section.id} id={section.id} previewQueryLayout={queryParams['ImageText']} />
                      );

                    case 'Rich Text Editor':
                      return (
                        <RichTextEditor
                          key={section.id}
                          name={`rte-${section.id}`}
                          id={section.id}
                          layoutName={queryParams['rte'] || section.layoutName}
                        />
                      );
                    case 'Product Showcase':
                      section = section as ProductsSection;
                      return (
                        <ProductList
                          id={section.id}
                          addToast={this.props.addToast}
                          cart={this.props.cart}
                          storeInfo={this.props.storeInfo}
                          addProductToCart={this.props.addProductToCart}
                          subtractProductFromCart={this.props.subtractProductFromCart}
                          key={section.id}
                          previewQueryLayout={queryParams['PS']}
                        />
                      );
                    case 'Category Showcase':
                      return <CategoryList key={section.id} id={section.id} previewQueryLayout={queryParams['CS']} />;

                    case 'HTML Block':
                      return (
                        <HtmlBlock
                          key={section.id}
                          id={section.id}
                          layoutName={queryParams['html-block'] || section.layoutName}
                        />
                      );

                    case 'Testimonial':
                      return (
                        <Testimonials
                          key={section.id}
                          id={section.id}
                          previewQueryLayout={queryParams['Testimonial']}
                        />
                      );

                    case 'Store Update':
                      return <StoreUpdate key={section.id} id={section.id} />;

                    default:
                      return null;
                  }
                })()}
              </>
            );
          })
          .filter((section) => {
            return section;
          })}
        <LoadMore fetchMoreUserSections={this.fetchMoreUserSections} />
        <div id="page-footer-scroll"></div>
      </PageLayout>
    );
  }
}

export default connect(
  ({
    products,
    features,
    categories,
    cart,
    recentlyViewed,
    home,
    userSections,
    slider,
    storeInfo,
    testimonials,
    referral,
    seo: { metaTags },
  }: RootState) => ({
    storeInfo,
    products,
    features,
    categories,
    cart,
    recentlyViewed,
    home,
    userSections,
    slider,
    testimonials,
    referral,
    metaTags,
  }),
  {
    fetchProductsRequest,
    fetchCategoriesRequest,
    fetchHomeRequest,
    fetchUserSectionsRequest,
    fetchMoreUserSectionsRequest,
    resetProductsStore,
    addProductToCart,
    subtractProductFromCart,
    removeProductFromCart,
    addToast,
    fetchReferralCodeDetailsRequest,
    resetReferralCodeDetails,
  },
)(Home);
