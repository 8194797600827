import { ToastVariant } from '@common/../lib-components/Toast';
import { deleteData, getData, postData, URLs } from '@common/api';
import * as Wishlist from '@common/models/Wishlist';
import { RouteMap } from '@common/RouteMap';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { addToast } from '../toasts/slice';
import {
  addToWishlist,
  addToWishlistFailure,
  addToWishlistSuccess,
  fetchWishListFailure,
  fetchWishListRequest,
  fetchWishListSuccess,
  getProdWishlist,
  getProdWishlistFailure,
  getProdWishlistSuccess,
  removeFromWishlist,
  removeFromWishlistFailure,
  removeFromWishlistSuccess,
  UserWishlistQueryParams,
  WISHLIST_TOAST,
} from './slice';

function* fetchWishListSaga({ payload: { queryParams } }: PayloadAction<{ queryParams: UserWishlistQueryParams }>) {
  try {
    const { data } = yield call(() => getData({ url: URLs.GET_WISHLIST, params: queryParams }));
    data.results = data.results?.map((product) => Wishlist.processResponse(product.product));
    yield put(fetchWishListSuccess({ response: data, queryParams }));
  } catch (e) {
    yield put(fetchWishListFailure('Request failed with: ' + e));
  }
}

function* addToWishListSaga({ payload: productId }: PayloadAction<string>) {
  try {
    const response = yield call(() =>
      postData({
        url: URLs.POST_WISHLIST,
        data: { product: productId },
      }),
    );
    yield put(addToWishlistSuccess(response));
    yield put(
      addToast({
        content: `Added to your <a 
        href=${RouteMap.ACCOUNT_WISHLIST}
        class="underline">Wishlist</a>`,
        type: 'addWishlist',
        variant: ToastVariant.success,
        dismissAfterMillis: 3000,
        options: [{ name: WISHLIST_TOAST }],
      }),
    );
  } catch (e) {
    yield put(addToWishlistFailure('Request failed with: ' + e));
  }
}

function* removeFromWishlistSaga({ payload: productId }: PayloadAction<string>) {
  try {
    const response = yield call(() =>
      deleteData({
        url: URLs.POST_WISHLIST,
        data: { product: productId },
      }),
    );
    yield put(removeFromWishlistSuccess(response));
    yield put(
      addToast({
        content: `Removed from your Wishlist`,
        type: 'removeWishlist',
        variant: ToastVariant.success,
        dismissAfterMillis: 3000,
        options: [{ name: WISHLIST_TOAST }],
      }),
    );
  } catch (e) {
    yield put(removeFromWishlistFailure('Request failed with: ' + e));
  }
}

function* getProdWishListSaga({ payload: productId }: PayloadAction<string>) {
  try {
    const response = yield call(() =>
      getData({
        url: URLs.POST_WISHLIST,
        params: { product: productId },
      }),
    );
    yield put(getProdWishlistSuccess(response));
  } catch (e) {
    yield put(getProdWishlistFailure('Request failed with: ' + e));
  }
}

export default function* wishListSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchWishListRequest.type, fetchWishListSaga);
  yield takeEvery(addToWishlist.type, addToWishListSaga);
  yield takeEvery(removeFromWishlist.type, removeFromWishlistSaga);
  yield takeEvery(getProdWishlist.type, getProdWishListSaga);
}
