import { shouldUseCustomNavigation } from '@common/utils';

interface Wishlist {
  id: number;
  title: string;
  currentPrice: number;
  image: string;
  groupId: string;
  productLink: string;
  optionNames: Array<string>;
  optionValues: Array<string>;
}
export const NUM_VARIANTS = 3;

export const processResponse = (wishlist: Record<string, never>): Wishlist => {
  // options: [{name, value:},  {name, value}, {name, value}]
  const productLink = shouldUseCustomNavigation()
    ? `/${wishlist.slug}/p${wishlist.group_id}`
    : `/product/${wishlist.group_id}/${wishlist.slug}`;

  let optionNames = [];
  for (let i = 1; i <= NUM_VARIANTS; i++) {
    optionNames.push(wishlist[`option${i}_name`]);
  }
  optionNames = optionNames.filter((value) => value);

  let optionValues = [];
  for (let i = 1; i <= NUM_VARIANTS; i++) {
    optionValues.push(wishlist[`option${i}_value`]);
  }
  optionValues = optionValues.filter((value) => value);

  return {
    id: wishlist.id,
    title: wishlist.title,
    image: wishlist.image,
    currentPrice: wishlist.current_price,
    productLink: productLink,
    groupId: wishlist.group_id,
    optionNames,
    optionValues,
  };
};

export default Wishlist;
