import capitalize from 'lodash/capitalize';

import { CASH_ON_DELIVERY } from './../utils/constants';

interface Order {
  id: number;
  orderId: string;
  billingAddress: {
    billingFullName: string;
    billingAddressLine1: string;
    billingAddressLine2: string;
    billingCity: string;
    billingZipCode: string;
    billingState: string;
    billingCountry: {
      code: string;
      name: string;
    };
  };
  firstname: string;
  lastname: string;
  email: string;
  created: string;
  address: string;
  city: string;
  state: string;
  code: string;
  country: string;
  contact: string;
  orderProductType: string;
  totalOrder: number;
  walletAmount: number;
  paymentMethod: string;
  paymentStatus: string;
  codVerified: boolean;
  orderStatus: string;
  prettyOrderStatus: string;
  isCompleted: boolean;
  isNoneOfTheStatuses: boolean;
  orderJson: Record<string, unknown>;
  details: {
    items: OrderItem[];
    discount: number;
    shippingCharge: number;
    codCharge: number;
    subtotal: number;
    netOrder: number;
    totalOrder: number;
    promoCode: string;
    promoData: Record<string, unknown>;
    cookieData: Record<string, unknown>;
    username: string;
    status: string;
    wsBreakup: Record<string, unknown> | null;
  };
  orderStatusHeadingArray: (string | null)[];
  orderStatusSmallHeadingArray: (string | null)[];
  orderStatusFulfilledArray: boolean[];
  user: string;
  paymentInstrument: string;
  comments: string;
  taxes: {
    totalTaxAmount: number;
    taxIncludedInventory: boolean;
  };
}

interface OrderItem {
  productType: string;
  payWhatYouWant: boolean;
  id: number;
  g: number;
  quantity: number;
  image: string | null;
  maximumOrderQuantity: number;
  price: number;
  finalPrice: number;
  categories: unknown[];
  storeLink: string;
  cod: boolean;
  prepaid: boolean;
  returns: boolean;
  replace: boolean;
  note: string | null;
  baseProductId: number;
  title: string;
  stock: number;
  sku: string;
  minimumOrderQuantity: number;
  netProductPrice: number;
  digitalFile?: string;
  digitalFileUrl?: string;
  digitalFileKey?: string;
}

const processOrderItemResponse = (orderItem: Record<string, never> = {}): OrderItem => {
  return {
    productType: orderItem.product_type,
    payWhatYouWant: orderItem.pay_what_you_want,
    id: orderItem.id,
    g: orderItem.g,
    quantity: orderItem.quantity,
    image: orderItem.image,
    maximumOrderQuantity: +orderItem.maxoq,
    price: orderItem.price,
    finalPrice: +orderItem.final_price,
    categories: orderItem.categories,
    storeLink: orderItem.store_link,
    cod: orderItem.cod,
    prepaid: orderItem.prepaid,
    returns: orderItem.returns,
    replace: orderItem.replace,
    note: orderItem.note,
    baseProductId: orderItem.base_product_id,
    title: orderItem.title,
    stock: orderItem.stock,
    sku: orderItem.sku,
    minimumOrderQuantity: orderItem.moq,
    netProductPrice: +orderItem.net_product_price,
    digitalFile: orderItem.digital_file,
    digitalFileUrl: orderItem.digital_file_url,
    digitalFileKey: orderItem.digital_file_key,
  };
};

export const processResponse = (order: Record<string, never>): Order => {
  const orderDetails = (order.details || {}) as Record<string, never>;
  if (order.payment_method === CASH_ON_DELIVERY) ORDER_STATUS.INITIATED.heading = 'Order placed';
  let orderStatusTrackArray = [
    ORDER_STATUS.INITIATED,
    ORDER_STATUS.PROCESSING,
    ORDER_STATUS.DISPATCHED,
    ORDER_STATUS.IN_TRANSIT,
    ORDER_STATUS.OUT_FOR_DELIVERY,
    ORDER_STATUS.AVAILABLE_FOR_PICKUP,
    ORDER_STATUS.COMPLETED,
    ORDER_STATUS.CANCEL_REQUEST,
    ORDER_STATUS.CANCEL_REQUEST_REJECTED,
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.FAILED_ATTEMPT,
    ORDER_STATUS.EXPIRED,
    ORDER_STATUS.EXCEPTION,
  ];
  let fulfilled = true;
  let orderStatus = order.order_status as string;
  if (
    orderStatus === 'in transit' ||
    orderStatus === 'out for delivery' ||
    orderStatus === 'available for pickup' ||
    orderStatus === 'failed attempt'
  )
    orderStatus = orderStatus.split(' ').join('-');
  const prettyOrderStatus = (orderStatus as string)
    ?.replace(/_/g, ' ')
    ?.replace(/-/g, ' ')
    ?.split(' ')
    ?.map((s) => s?.toLowerCase())
    ?.map(capitalize)
    ?.join(' ');

  const isNoneOfTheStatuses = !orderStatusTrackArray.reduce((acc, curr) => {
    return acc || (curr.regex.test(orderStatus) && !CANCEL_ORDER_STATUSES.includes(orderStatus));
  }, false);
  if (isNoneOfTheStatuses) {
    orderStatusTrackArray.shift();
    orderStatusTrackArray.unshift({
      regex: /.*/i,
      heading: `${
        prettyOrderStatus === 'Cancel Request'
          ? 'Cancellation requested (Cancellation request sent to the Seller)'
          : prettyOrderStatus === 'Cancelled'
          ? `Cancelled (Order cancelled)`
          : prettyOrderStatus === 'Cancellation Rejected'
          ? 'Cancellation request rejected (Seller rejected the cancellation request)'
          : prettyOrderStatus === 'Failed Attempt'
          ? 'Failed attempt (The carrier attempted to deliver the shipment but failed)'
          : prettyOrderStatus === `Expired`
          ? `Expired (carrier didn't return the tracking info for the last 30 days)`
          : prettyOrderStatus === 'Exception'
          ? 'Exception (Held at customs/ undelivered/ returned to sender)'
          : `Order ${prettyOrderStatus}`
      }`,
      smallHeading: `${prettyOrderStatus}`,
      orderStatus: '',
    });
  }

  if (isNoneOfTheStatuses) {
    orderStatusTrackArray = orderStatusTrackArray.filter((x) => x.orderStatus !== orderStatus);
  }

  const orderStatusFulfilledArray = orderStatusTrackArray.map((item) => {
    if (fulfilled) {
      if (!item.regex.test(orderStatus)) {
        return true;
      } else {
        fulfilled = false;
        return true;
      }
    } else {
      return false;
    }
  });

  let billingAddress = null;
  if ((order.billing_address as Record<string, string>)?.billing_state) {
    billingAddress = {
      billingFullName: (order.billing_address as Record<string, string>)?.billing_full_name,
      billingAddressLine1: (order.billing_address as Record<string, string>)?.billing_address_line1,
      billingAddressLine2: (order.billing_address as Record<string, string>)?.billing_address_line2,
      billingCity: (order.billing_address as Record<string, string>)?.billing_city,
      billingZipCode: (order.billing_address as Record<string, string>)?.billing_zip_code,
      billingState: (order.billing_address as Record<string, string>)?.billing_state,
      billingCountry: {
        code: (order.billing_address as Record<string, Record<string, string>>)?.billing_country?.code,
        name: (order.billing_address as Record<string, Record<string, string>>)?.billing_country?.name,
      },
    };
  }

  return {
    id: order.id,
    orderId: order.orderid,
    billingAddress,
    created: order.created,
    firstname: order.firstname,
    lastname: order.lastname,
    orderStatusHeadingArray: orderStatusTrackArray.map((x) => x.heading),
    orderStatusSmallHeadingArray: orderStatusTrackArray.map((x) => x.smallHeading),
    orderStatusFulfilledArray,
    email: order.email,
    address: order.address,
    city: order.city,
    state: order.state,
    code: order.code,
    country: order.country,
    contact: order.contact,
    orderProductType: order.order_prod_type,
    isCompleted: ORDER_STATUS.COMPLETED.regex.test(orderStatus),
    isNoneOfTheStatuses,
    totalOrder: +order.total_order,
    walletAmount: +order.wallet_amount,
    paymentMethod: order.payment_method,
    paymentStatus: order.payment_status,
    codVerified: order.cod_verified,
    orderStatus: orderStatus,
    prettyOrderStatus,
    orderJson: JSON.parse(order.order_json),
    details: {
      items: (orderDetails?.items as Record<string, never>[])?.map((orderItem) => processOrderItemResponse(orderItem)),
      discount: +orderDetails.discount,
      shippingCharge: +orderDetails.shipping_charge,
      codCharge: +orderDetails.cod_charge,
      subtotal: +orderDetails.subtotal,
      netOrder: +orderDetails.net_order,
      totalOrder: +orderDetails.total_order,
      promoCode: orderDetails.promo_code,
      promoData: orderDetails.promo_data,
      cookieData: orderDetails.cookie_data,
      username: orderDetails.username,
      status: orderDetails.status,
      wsBreakup: orderDetails.ws_breakup,
    },
    user: order.user,
    paymentInstrument: order?.payment_instrument,
    comments: order.comments,
    taxes: {
      totalTaxAmount: +(order.taxes as Record<string, never>)?.total_tax_amount,
      taxIncludedInventory: (order.taxes as Record<string, never>)?.tax_included_inventory,
    },
  };
};

export default Order;

export const ORDER_STATUS = {
  INITIATED: {
    regex: /initiated/i,
    heading: 'Order Placed (Received payment)',
    smallHeading: 'Order Placed',
    orderStatus: 'initiated',
  },
  PROCESSING: {
    regex: /process/i,
    heading: 'Processing (Order is getting ready for delivery)',
    smallHeading: 'Processing',
    orderStatus: 'processing',
  },
  DISPATCHED: {
    regex: /dispatch/i,
    heading: 'Dispatched (Shipment on it’s way to the delivery address)',
    smallHeading: 'Dispatched',
    orderStatus: 'dispatched',
  },
  IN_TRANSIT: {
    regex: /in-transit/i,
    heading: 'In transit (Package reached the designated city)',
    smallHeading: 'In transit',
    orderStatus: 'in-transit',
  },
  OUT_FOR_DELIVERY: {
    regex: /out-for-delivery/i,
    heading: 'Out for delivery(Package on its way to the specified address)',
    smallHeading: 'Out for delivery',
    orderStatus: 'out-for-delivery',
  },
  AVAILABLE_FOR_PICKUP: {
    regex: /available-for-pickup/i,
    heading: 'Available for pickup (The Package arrived at your designated pickup point and is available for pickup)',
    smallHeading: 'Available for pickup',
    orderStatus: 'available-for-pickup',
  },
  COMPLETED: {
    regex: /complete/i,
    heading: 'Delivered (Package Delivered)',
    smallHeading: 'Delivered',
    orderStatus: 'completed',
  },
  CANCEL_REQUEST: {
    regex: /cancel-request/i,
    heading: 'Cancellation requested ( Cancellation request sent to the Seller )',
    smallHeading: 'Cancellation requested',
    orderStatus: 'cancel-request',
  },
  CANCEL_REQUEST_REJECTED: {
    regex: /cancellation-rejected/i,
    heading: 'Cancellation request rejected (Seller rejected the cancellation request)',
    smallHeading: 'Cancellation request rejected',
    orderStatus: 'cancellation-rejected',
  },
  CANCELLED: {
    regex: /cancelled/i,
    heading: 'Cancelled (Order cancelled)',
    smallHeading: 'Cancelled',
    orderStatus: 'cancelled',
  },
  FAILED_ATTEMPT: {
    regex: /failed-attempt/i,
    heading: 'Failed attempt (The carrier attempted to deliver the shipment but failed)',
    smallHeading: 'Failed attempt',
    orderStatus: 'failed-attempt',
  },
  EXPIRED: {
    regex: /expired/i,
    heading: `Expired (carrier didn't return the tracking info for the last 30 days)`,
    smallHeading: 'Expired',
    orderStatus: 'expired',
  },
  EXCEPTION: {
    regex: /exception/i,
    heading: `Exception (Held at customs/ undelivered/ returned to sender)`,
    smallHeading: 'Exception',
    orderStatus: 'exception',
  },
};

const CANCEL_ORDER_STATUSES = [
  'cancel-request',
  'cancellation-rejected',
  'cancelled',
  'failed-attempt',
  'expired',
  'exception',
];
