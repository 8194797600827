// import Category from '@common/models/Category';
import '@common/styles/layouts.css';

import { RootState } from '@common/store';
import { CategoriesSliceType } from '@common/store/categories/slice';
import { fetchCategoriesProductCountRequest } from '@common/store/categoriesProductCount/slice';
import {
  fetchCategoriesProductsListRequest,
  fetchNextCategoriesProductsListRequest,
} from '@common/store/categoriesProductsList/slice';
import { fetchCategoryListRequest } from '@common/store/categoryList/slice';
import loadable from '@loadable/component';
import { DEFAULT_CATEGORYLIST_LAYOUT_SELECTION } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';

export interface LayoutProps {
  categories: CategoriesSliceType;
  layout: string;
  fetchCategoryListRequest: fetchCategoryListRequest.type;
  fetchCategoriesProductCountRequest: fetchCategoriesProductCountRequest.type;
  fetchCategoriesProductsListRequest: fetchCategoriesProductsListRequest.type;
  fetchNextCategoriesProductsListRequest: fetchNextCategoriesProductsListRequest.type;
  categoryList: [];
  id: number;
  layoutName: string;
  previewQueryLayout: string;
  categoriesProductCount: [];
}

const CategoryList1 = loadable(() => import(/* webpackChunkName: "CS1" */ './CategoryList1')); //Desire
const CategoryList2 = loadable(() => import(/* webpackChunkName: "CS2" */ './CategoryList2')); //Phulkari
const CategoryList3 = loadable(() => import(/* webpackChunkName: "CS3" */ './CategoryList3')); //Accent
const CategoryList4 = loadable(() => import(/* webpackChunkName: "CS4" */ './CategoryList4')); //Ikkat
const CategoryList5 = loadable(() => import(/* webpackChunkName: "CS5" */ './CategoryList5')); //Esteem
const CategoryList6 = loadable(() => import(/* webpackChunkName: "CS6" */ './CategoryList6')); //Bright
const CategoryList7 = loadable(() => import(/* webpackChunkName: "CS7" */ './CategoryList7')); //Flow
const CategoryList8 = loadable(() => import(/* webpackChunkName: "CS8" */ './CategoryList8')); //Runway
const CategoryList9 = loadable(() => import(/* webpackChunkName: "CS9" */ './CategoryList9')); //Spring
const CategoryList10 = loadable(() => import(/* webpackChunkName: "CS10" */ './CategoryList10')); //compass
const CategoryList11 = loadable(() => import(/* webpackChunkName: "CS11" */ './CategoryList11')); //Rugged
const CategoryList12 = loadable(() => import(/* webpackChunkName: "CS12" */ './CategoryList12')); //Cipher
const CategoryList13 = loadable(() => import(/* webpackChunkName: "CS13" */ './CategoryList13')); //Essense
const CategoryList14 = loadable(() => import(/* webpackChunkName: "CS14" */ './CategoryList14')); //Gulab
const CategoryList15 = loadable(() => import(/* webpackChunkName: "CS15" */ './CategoryList15')); //Sakra
const CategoryList16 = loadable(() => import(/* webpackChunkName: "CS16" */ './CategoryList16')); //coral
const CategoryList17 = loadable(() => import(/* webpackChunkName: "CS17" */ './CategoryList17')); //Florencia
const CategoryList18 = loadable(() => import(/* webpackChunkName: "CS18" */ './CategoryList18')); //Florencia
const CategoryList19 = loadable(() => import(/* webpackChunkName: "CS19" */ './CategoryList19')); //Florencia
const CategoryList20 = loadable(() => import(/* webpackChunkName: "CS20" */ './CategoryList20')); //Oceanna
const CategoryList21 = loadable(() => import(/* webpackChunkName: "CS21" */ './CategoryList21')); //Oceanna
const CategoryList22 = loadable(() => import(/* webpackChunkName: "CS22" */ './CategoryList22')); //Oceanna
const CategoryList23 = loadable(() => import(/* webpackChunkName: "CS23" */ './CategoryList23')); //Himalaya
const CategoryList24 = loadable(() => import(/* webpackChunkName: "CS24" */ './CategoryList24')); //Himalaya
const CategoryList25 = loadable(() => import(/* webpackChunkName: "CS25" */ './CategoryList25')); //Himalaya

class CategoryList extends PureComponent<LayoutProps> {
  constructor(props) {
    super(props);
    const { fetchCategoryListRequest, id } = this.props;
    if (id.toString() !== ADD_NEW_SECTION_ID) {
      fetchCategoryListRequest({ sectionId: id });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      categoryList,
      categories,
      fetchCategoriesProductsListRequest,
      previewQueryLayout,
      fetchCategoriesProductCountRequest,
    } = this.props;
    const layoutName = previewQueryLayout || categoryList[id]?.layout?.name || DEFAULT_CATEGORYLIST_LAYOUT_SELECTION;
    if (prevProps.categoryList[id]?.list !== this.props.categoryList[id]?.list) {
      if (layoutName === 'CS24') {
        const categoriesToBeShown =
          (categoryList[id]?.showcaseType === 2
            ? categories?.categories?.featuredCategories
            : categoryList[id]?.list?.results) || [];
        const categoriesToBeShownIds = categoriesToBeShown?.map((item) => item.id).slice(0, 5);
        const categoriesIds = categoriesToBeShownIds.toString();
        fetchCategoriesProductsListRequest({ categoriesIds, id });
      }
      if (layoutName === 'CS22') {
        fetchCategoriesProductCountRequest();
      }
    }
  }

  render(): React.ReactElement {
    const {
      id,
      categoryList,
      categories,
      previewQueryLayout,
      categoriesProductCount,
      categoriesProductsList,
      fetchNextCategoriesProductsListRequest,
    } = this.props;
    const categoriesToBeShown =
      (categoryList[id]?.showcaseType === 2
        ? categories?.categories?.featuredCategories
        : categoryList[id]?.list?.results) || [];
    const layoutName = previewQueryLayout || categoryList[id]?.layout?.name || DEFAULT_CATEGORYLIST_LAYOUT_SELECTION;
    return categoriesToBeShown.length > 0 && layoutName ? (
      <div className="bg-categorySection" id={id.toString()}>
        <>
          {layoutName === 'CS1' ? (
            <CategoryList1
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS2' ? (
            <CategoryList2
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS3' ? (
            <CategoryList3
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS4' ? (
            <CategoryList4
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS5' ? (
            <CategoryList5
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS6' ? (
            <CategoryList6
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS7' ? (
            <CategoryList7
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS8' ? (
            <CategoryList8
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS9' ? (
            <CategoryList9
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS10' ? (
            <CategoryList10
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS11' ? (
            <CategoryList11
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS12' ? (
            <CategoryList12
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS13' ? (
            <CategoryList13
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS14' ? (
            <CategoryList14
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS15' ? (
            <CategoryList15
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS16' ? (
            <CategoryList16
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS17' ? (
            <CategoryList17
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
          {layoutName === 'CS18' ? (
            <CategoryList18
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS19' ? (
            <CategoryList19
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS20' ? (
            <CategoryList20
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS21' ? (
            <CategoryList21
              {...this.props}
              categories={categoriesToBeShown}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS22' ? (
            <CategoryList22
              {...this.props}
              categories={categoriesToBeShown}
              categoriesProductCount={categoriesProductCount}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS23' ? (
            <CategoryList23
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS24' ? (
            <CategoryList24
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              categoriesProductsList={categoriesProductsList}
              fetchCategoriesProductsListRequest={fetchNextCategoriesProductsListRequest}
              fallback={<CategoryListFallback />}
            />
          ) : null}

          {layoutName === 'CS25' ? (
            <CategoryList25
              {...this.props}
              categories={categoriesToBeShown}
              heading={categoryList[id]?.title}
              id={id}
              fallback={<CategoryListFallback />}
            />
          ) : null}
        </>
      </div>
    ) : null;
  }
}

export default connect(
  ({ categoryList, categories, categoriesProductCount, categoriesProductsList }: RootState) => ({
    categoryList,
    categories,
    categoriesProductCount,
    categoriesProductsList,
  }),
  {
    fetchCategoryListRequest,
    fetchCategoriesProductCountRequest,
    fetchCategoriesProductsListRequest,
    fetchNextCategoriesProductsListRequest,
  },
)(CategoryList);

const CategoryListFallback = () => {
  return <div></div>;
};

// todo
// update Cipher feature category
