export const validateRequired = (value: unknown, errorText = 'This field is required'): string | undefined => {
  if (value == null || value == '') {
    return errorText;
  }
};

export const validateEmail = (email: unknown, errorText = 'Invalid email'): string | undefined => {
  if (email == null || email == '') {
    return errorText;
  }
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(String(email).toLowerCase())) {
    return errorText;
  }
};

export const validateNumber = (number: unknown, errorText = 'Not a Number'): string | undefined => {
  if (number == null || number == '') {
    return errorText;
  }
  // if (!/[+-]?([0-9]*[.])?[0-9]+/.test((number as string) ?? '')) {
  //   return errorText;
  // }
  const parsedNumber = Number(number);
  if (isNaN(parsedNumber)) {
    return errorText;
  }
};

export const validateGreaterThanEqualtoZero = (
  number: unknown,
  errorText = 'Must be non negative',
): string | undefined => {
  if (number == null || number == '') {
    return errorText;
  }
  // if (!/[+-]?([0-9]*[.])?[0-9]+/.test((number as string) ?? '')) {
  //   return errorText;
  // }
  const parsedNumber = Number(number);
  if (isNaN(parsedNumber)) {
    return 'Not a number';
  }

  if (parsedNumber < 0) {
    return errorText;
  }
};

export const validateMobile = (mobile: unknown, errorText = 'Invalid mobile number'): string | undefined => {
  if (mobile == null || mobile == '') {
    return errorText;
  }
  const regex = /^\d{10}$/;
  if (!regex.test(String(mobile).toLowerCase())) {
    return errorText;
  }
};

export const validateZipCode = (code: string, errorText = 'Invalid Pin Code', country: string): string | undefined => {
  if (country === 'IN') {
    if (code == null || code == '') {
      return errorText;
    }
    const regex = /^\d{6}$/;
    if (!regex.test(String(code).toLowerCase())) {
      return errorText;
    }
  } else {
    if (!+code) return errorText;
  }
};

export const validateDate = (date: unknown, errorText = 'Invalid date'): string | undefined => {
  if (date == null || date == '') {
    return;
  }
  const regex = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/;
  if (!regex.test(String(date).toLowerCase())) {
    return errorText;
  }
  const elements = String(date).split('-');
  if (isNaN(Date.parse(`${elements[2]}-${elements[1]}-${elements[0]}`))) {
    return errorText;
  }
};

export const validateEquality = (first: unknown, second: unknown, message: string): string => {
  if (first == second) {
    return;
  } else {
    return message;
  }
};
const PHONE_NO_LENGTH_WITH_DIALCODE = {
  in: 12,
  us: 11,
};
export const validateMobileWithCountryCode = (
  mobile: unknown,
  countryCode: string,
  errorText = 'Invalid mobile number',
): string | undefined => {
  if (mobile == null || mobile == '') {
    return errorText;
  }
  const count = PHONE_NO_LENGTH_WITH_DIALCODE[countryCode] || 12;
  const regex = new RegExp(`^\\d{` + count + `}$`);
  if (!regex.test(String(mobile).toLowerCase().replace('+', ''))) {
    return errorText;
  }
};

export const validateDateLessThanCurrent = (date: unknown, errorText = 'Invalid date'): string | undefined => {
  if (date == null || date == '') {
    return;
  }
  const regex = /^([0-9]{2})-([0-9]{2})-([0-9]{4})$/;
  if (!regex.test(String(date).toLowerCase())) {
    return errorText;
  }
  const elements = String(date).split('-');
  const parsedDate = Date.parse(`${elements[2]}-${elements[1]}-${elements[0]}`);
  if (isNaN(parsedDate)) {
    return errorText;
  }

  const currentDate = new Date();
  if (new Date(parsedDate) > currentDate) {
    return 'Specified date is in the future';
  }
};

export const validateFileSize = (
  filesize: number,
  maxFileSize: number,
  errorText = `File size must be less than ${maxFileSize / (1024 * 1024)} MB`,
): string | undefined => {
  if (filesize > maxFileSize) {
    return errorText;
  }
  return null;
};
