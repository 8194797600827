/* eslint-disable @typescript-eslint/no-unused-vars */
import '@common/styles/layouts.css';

import PageHeader from '@common/models/PageHeader';
import { Banner as BannerType } from '@common/models/sections/Banner';
import UserSections from '@common/models/UserSections';
import { RootState } from '@common/store';
import { fetchSliderRequest } from '@common/store/heroBanner/slice';
import { SliderSliceType } from '@common/store/slider/slice';
import loadable from '@loadable/component';
import { DEFAULT_SLIDER_LAYOUT_SELECTION, SLIDER_TOP_PADDING } from '@theme/utils/constants';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';
import SliderMain from './SliderMain';

export interface SliderProps {
  // slider: SliderSliceType;
  id: string;
  layout: string;
  layoutName: string;
  banner: BannerType;
  fetchSliderRequest: fetchSliderRequest.type;
  heroBanner: SliderSliceType;
  isFirstSection: boolean;
  previewQueryLayout?: string;
  userSections: UserSections;
  pageHeader: PageHeader;
}

const HeroBanner1 = loadable(() => import(/* webpackChunkName: "HB1" */ './HeroBanner1')); //Flow  HB1
const HeroBanner2 = loadable(() => import(/* webpackChunkName: "HB2" */ './HeroBanner2')); //Melody,Grace,Sakra  HB3
const HeroBanner3 = loadable(() => import(/* webpackChunkName: "HB3" */ './HeroBanner3')); //coral,Esteem,Rugged  HB4
const HeroBanner4 = loadable(() => import(/* webpackChunkName: "HB4" */ './HeroBanner4')); //Gulab  HB5
const HeroBanner5 = loadable(() => import(/* webpackChunkName: "HB5" */ './HeroBanner5')); //Accent,Essense,Bright,Ikkat,Cipher,Spring  HB7
const HeroBanner6 = loadable(() => import(/* webpackChunkName: "HB6" */ './HeroBanner6')); //Runway  HB8
const HeroBanner7 = loadable(() => import(/* webpackChunkName: "HB7" */ './HeroBanner7')); //Compass  HB9
const HeroBanner8 = loadable(() => import(/* webpackChunkName: "HB8" */ './HeroBanner8')); //Desire Comfort,Phulkari  HB10
const HeroBanner9 = loadable(() => import(/* webpackChunkName: "HB9" */ './HeroBanner9')); //Florencia  HB09
const HeroBanner10 = loadable(() => import(/* webpackChunkName: "HB10" */ './HeroBanner10')); //Himalayan
const HeroBanner11 = loadable(() => import(/* webpackChunkName: "HB11" */ './HeroBanner11')); //Himalayan

class Slider extends PureComponent<SliderProps> {
  constructor(props) {
    super(props);
    const { fetchSliderRequest, id, heroBanner } = this.props;
    if (!heroBanner[id]?.isFetched && !heroBanner[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchSliderRequest(id);
    }
  }
  render(): React.ReactElement {
    const { heroBanner, id, isFirstSection, previewQueryLayout, userSections, pageHeader } = this.props;
    const layoutName = previewQueryLayout || heroBanner[id]?.layout?.name || DEFAULT_SLIDER_LAYOUT_SELECTION;
    const pageHeaderID = userSections?.userSections[0]?.id;
    const isPageHeader9 = pageHeader[pageHeaderID]?.layout?.name === 'PH9';
    return (
      <>
        {heroBanner[id] && layoutName ? (
          <SliderMain
            className={`slider-containerBg slider-container ${!isPageHeader9 && 'layout-container'} ${
              isFirstSection && layoutName !== 'HB11' ? `pb-40 ${isPageHeader9 ? 'pt-0' : SLIDER_TOP_PADDING}` : 'py-40'
            }`}
            id={id}
            slider={heroBanner[id]}
          >
            {layoutName === 'HB1' ? (
              <HeroBanner1 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB2' ? (
              <HeroBanner2 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB3' ? (
              <HeroBanner3 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB4' ? (
              <HeroBanner4 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB5' ? (
              <HeroBanner5 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB6' ? (
              <HeroBanner6 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB7' ? (
              <HeroBanner7 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB8' ? (
              <HeroBanner8 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB9' ? (
              <HeroBanner9 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} />
            ) : null}
            {layoutName === 'HB10' ? (
              <HeroBanner10 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} sliderId={id} />
            ) : null}
            {layoutName === 'HB11' ? (
              <HeroBanner11 fallback={<SliderFallback />} {...this.props} slider={heroBanner[id]} sliderId={id} />
            ) : null}
          </SliderMain>
        ) : null}
      </>
    );
  }
}

const SliderFallback = () => {
  return <div></div>;
};

export default connect(
  ({ heroBanner, pageHeader, userSections }: RootState) => ({
    heroBanner,
    pageHeader,
    userSections,
  }),
  {
    fetchSliderRequest,
  },
)(Slider);
