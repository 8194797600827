import { getData, URLs } from '@common/api';
import * as Product from '@common/models/Product';
import { productListLayoutdimensions } from '@common/models/Product';
import { isMobile } from '@common/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PRODUCTLIST_LAYOUT_SELECTION } from '@theme/utils/constants';
import { all, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchProductListFailure,
  fetchProductListRequest,
  fetchProductListSuccess,
  FetchProductsRequestPayloadType,
} from './slice';

function* fetchProductListSaga({
  payload: { sectionId, useCache, fetchAfterServe, previewQueryLayout },
}: PayloadAction<FetchProductsRequestPayloadType>) {
  try {
    const [{ data }, sectionData] = yield all([
      call(() =>
        getData({
          url: URLs.GET_PRODUCTSLIST.replace('{id}', sectionId),
          useCache,
          fetchAfterServe,
        }),
      ),

      call(() =>
        getData({
          url: URLs.GET_PRODUCTSLIST_SECTION.replace('{id}', sectionId),
          useCache,
          fetchAfterServe,
        }),
      ),
    ]);

    const layout = sectionData?.data?.layout?.name;
    let productDetails = null;
    const productId = data.results[0]?.id;
    const layoutName =
      sectionData?.data?.showcase_type === 4
        ? DEFAULT_PRODUCTLIST_LAYOUT_SELECTION
        : previewQueryLayout || layout || DEFAULT_PRODUCTLIST_LAYOUT_SELECTION;
    const { desktop, mobile } = productListLayoutdimensions[layoutName] || {
      mobile: { width: 0, height: 0 },
      desktop: { width: 0, height: 0 },
    };
    if (layout === 'PS13' || previewQueryLayout === 'PS13') {
      const { data } = yield call(() =>
        getData({
          url: URLs.GET_PRODUCT_DETAILS.replace('{id}', productId),
          useCache,
          fetchAfterServe,
        }),
      );
      productDetails = Product.processResponse(data, {
        productCardWidth: isMobile() ? mobile.width : desktop.width,
        productCardHeight: isMobile() ? mobile.height : desktop.height,
      });
    }

    data.results = data.results?.map((product) =>
      Product.processResponse(product, {
        productCardWidth: isMobile() ? mobile.width : desktop.width,
        productCardHeight: isMobile() ? mobile.height : desktop.height,
      }),
    );

    yield put(
      fetchProductListSuccess({
        sectionId,
        products: data,
        productDetails,
        title: sectionData?.data?.title,
        layout: sectionData?.data?.layout,
        showcaseType: sectionData?.data?.showcase_type,
      }),
    );
  } catch (e) {
    yield put(fetchProductListFailure({ sectionId, error: 'Request failed with: ' + e }));
  }
}

export default function* productListSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchProductListRequest.type, fetchProductListSaga);
}
