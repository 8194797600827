import '@common/styles/layouts.css';

import { RootState } from '@common/store';
import { fetchImageAndTextRequest, ImageAndTextSliceType } from '@common/store/imageAndText/slice';
import loadable from '@loadable/component';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';

export interface ImageAndTextLayoutProps {
  // slider: SliderSliceType;
  id: string;
  layout: string;
  layoutName: string;
  fetchImageAndTextRequest: fetchImageAndTextRequest.type;
  imageAndText: ImageAndTextSliceType;
}

const ImageAndText1 = loadable(() => import(/* webpackChunkName: "ImageAndText1" */ './ImageAndText1'));
const ImageAndText2 = loadable(() => import(/* webpackChunkName: "ImageAndText2" */ './ImageAndText2'));
const ImageAndText3 = loadable(() => import(/* webpackChunkName: "ImageAndText3" */ './ImageAndText3'));
const ImageAndText4 = loadable(() => import(/* webpackChunkName: "ImageAndText4" */ './ImageAndText4'));
const ImageAndText5 = loadable(() => import(/* webpackChunkName: "ImageAndText5" */ './ImageAndText5'));
const ImageAndText6 = loadable(() => import(/* webpackChunkName: "ImageAndText6" */ './ImageAndText6'));
const ImageAndText7 = loadable(() => import(/* webpackChunkName: "ImageAndText7" */ './ImageAndText7'));
const ImageAndText8 = loadable(() => import(/* webpackChunkName: "ImageAndText8" */ './ImageAndText8'));

class ImageAndTextLayout extends PureComponent<ImageAndTextLayoutProps> {
  constructor(props) {
    super(props);
    const { fetchImageAndTextRequest, id, imageAndText } = this.props;
    if (!imageAndText[id]?.isFetched && !imageAndText[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchImageAndTextRequest(id);
    }
  }

  componentDidUpdate() {
    this.setState({ imageAndText: this.props.imageAndText });
  }

  render(): React.ReactElement {
    const { imageAndText, id, previewQueryLayout } = this.props;
    const layoutName = previewQueryLayout || imageAndText[id]?.layout?.name || 'ImageText2';

    return (
      <>
        {imageAndText[id] && layoutName ? (
          <>
            {layoutName === 'ImageText1' ? (
              <ImageAndText1
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText2' ? (
              <ImageAndText2
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText3' ? (
              <ImageAndText3
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText4' ? (
              <ImageAndText4
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText5' ? (
              <ImageAndText5
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText6' ? (
              <ImageAndText6
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText7' ? (
              <ImageAndText7
                fallback={<ImageAndTextFallback />}
                imageAndText={imageAndText[id].imageAndText}
                className="container mx-auto"
              />
            ) : null}
            {layoutName === 'ImageText8' ? (
              <ImageAndText8 fallback={<ImageAndTextFallback />} imageAndText={imageAndText[id].imageAndText} />
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

const ImageAndTextFallback = () => {
  return <div>....</div>;
};

export default connect(
  ({ imageAndText }: RootState) => ({
    imageAndText,
  }),
  {
    fetchImageAndTextRequest,
  },
)(ImageAndTextLayout);
