import { RootState } from '@common/store';
import { fetchRichTextEditorRequest } from '@common/store/richTextEditor/slice';
import loadable from '@loadable/component';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ADD_NEW_SECTION_ID } from '../../common/utils/constants';

const RichTextEditorComponent = loadable(() => import('./RichTextEditorComponent'));

class RichTextEditor extends PureComponent<RichTextEditorProps> {
  constructor(props) {
    super(props);
    const { fetchRichTextEditorRequest, id, richTextEditor } = this.props;
    if (!richTextEditor[id]?.isFetched && !richTextEditor[id]?.isFetching && id !== ADD_NEW_SECTION_ID) {
      fetchRichTextEditorRequest(id);
    }
  }

  render(): React.ReactElement {
    const { richTextEditor, id, name } = this.props;
    return richTextEditor[id] && richTextEditor[id]?.richTextEditor.content ? (
      <div className="rte-container bg-rteSection">
        <div className="bg-rteBg layout-container container mx-auto py-40" id={id}>
          <RichTextEditorComponent
            fallback={<RichTextEditorFallback />}
            name={name}
            section={richTextEditor[id].richTextEditor}
          />{' '}
        </div>
      </div>
    ) : null;
  }
}

const RichTextEditorFallback = () => {
  return <div></div>;
};

export default connect(
  ({ richTextEditor }: RootState) => ({
    richTextEditor,
  }),
  {
    fetchRichTextEditorRequest,
  },
)(RichTextEditor);
