import React, { useEffect, useRef } from 'react';

export interface LoadMoreProps {
  fetchMoreUserSections?: () => void;
}

const LoadMore: React.FC<LoadMoreProps> = ({ fetchMoreUserSections }) => {
  const ref = useRef();
  useEffect(() => {
    const options = {
      threshold: 1.0,
      rootMargin: '400px',
    };

    const observer = new IntersectionObserver(() => {
      console.log('REACHED END OF SECTIONS');
      fetchMoreUserSections();
    }, options);
    const target = document.querySelector('#user-sections-end');
    observer.observe(target);
  }, []);
  return <div ref={ref} className="h-4" id="user-sections-end" />;
};

export default LoadMore;
