// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as HtmlBlock from '@common/models/HtmlBlock';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchHtmlBlockFailure, fetchHtmlBlockRequest, fetchHtmlBlockSuccess } from './slice';

export function* fetchHtmlBlockSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const data = yield call(() => getData({ url: URLs.GET_HTML_BLOCK.replace('{id}', payload) }));
    const result = HtmlBlock.processResponse(data.data);
    const { id } = data?.data;
    yield put(fetchHtmlBlockSuccess({ id: id, result }));
  } catch (e) {
    yield put(fetchHtmlBlockFailure('Request failed with: ' + e));
  }
}

export default function* htmlBlockSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchHtmlBlockRequest.type, fetchHtmlBlockSaga);
}
