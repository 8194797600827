interface Features {
  id: number;
  user: number;
  themes: number;
  customPages: number;
  customStoreLogo: boolean;
  referralDiscounts: boolean;
  customMetaTags: boolean;
  linkDomainToStore: boolean;
  shippingLabelGenerationPickup: boolean;
  bannerImages: number;
  whiteLabel: boolean;
  noOfProducts: number;
  noOfProductImages: number;
  productAttributes: boolean;
  productOptions: boolean;
  advancedFilters: boolean;
  wishList: boolean;
  productRating: boolean;
  productReviewFb: boolean;
  wholesalePricing: boolean;
  orderBulkOperations: boolean;
  crmBulkOperations: boolean;
  storePerformanceReport: number;
  lowStockAlert: boolean;
  multiplePaymentMethods: boolean;
  internationalPayments: boolean;
  multipleCurrencySupport: boolean;
  shippingIntegration: boolean;
  shippingSmsNotification: boolean;
  orderStatusSmsNotification: boolean;
  multipleShipmentPartners: boolean;
  codPincodeRestriction: boolean;
  cartValueBasedShipping: boolean;
  pincodeCheck: boolean;
  marketingSmsNotification: boolean;
  promoCodeMarketing: boolean;
  storeTestimonial: boolean;
  marketingEmail: number;
  personalizedEmailAndSms: boolean;
  emailAndSmsCampaign: boolean;
  crmProfileTagging: boolean;
  autoTweet: boolean;
  enduserPushNotification: boolean;
  autoPostOnFb: boolean;
  posSalesSync: boolean;
  facebookShop: boolean;
  gstTaxation: boolean;
  relatedProducts: boolean;
  recentlyViewed: boolean;
  enduserAndroidApp: boolean;
  enduserIosApp: boolean;
  imageEditor: boolean;
  cataloguePdfGeneration: boolean;
  ssl: boolean;
  businessEmail: boolean;
  productSearch: boolean;
  edtOrder: boolean;
  webmasterTools: boolean;
  weightBasedShippingCharges: boolean;
  customInvoicing: boolean;
  categoryTaxRules: boolean;
  regionalTaxRules: boolean;
  premiumThemes: boolean;
  repeatOrder: boolean;
  marketing: boolean;
  marketingSms: number;
  domainName: boolean;
  bankTransfer: boolean;
  ccavenue: boolean;
  udio: boolean;
  razorpay: boolean;
  hdfc: boolean;
  payubiz: boolean;
  axisbank: boolean;
  paytm: boolean;
  facebookTwitterLikeWidget: boolean;
  oneUp: boolean;
  oneUpAdvanced: boolean;
  onlineChat: boolean;
  marketingAndPromotion: boolean;
  crm: boolean;
  seo: boolean;
  googleAdwords: number;
  editCss: boolean;
  enduserFbRegistration: boolean;
  tdr: number;
  enduserApp: boolean;
  productReview: boolean;
  internationalStore: boolean;
  attributeBasedPricing: boolean;
  roleBasedLogin: boolean;
  numRoleBasedLogin: number;
  storeCredit: boolean;
  enduserType: boolean;
  enduserTypeBasedPricing: boolean;
  abandonedCart: boolean;
  zohoCrm: boolean;
  orderInvoiceZip: boolean;
  bulkShipmentCreate: boolean;
  bulkShippingDetailsUpload: boolean;
  storeUrlRedirection: boolean;
  buyAnywhereButton: boolean;
  customCourierPartner: boolean;
  partialOrderAction: boolean;
  customNavigation: boolean;
  pageSpecificScripts: boolean;
  basicSeo: boolean;
  contactUsPage: boolean;
  socialMediaIntegration: boolean;
  editContent: boolean;
  inventoryBulkEdit: boolean;
  inventoryBulkUpload: boolean;
  categories: boolean;
  productVariants: boolean;
  productPaymentWebhooks: boolean;
  bulkPinCodeUpload: boolean;
  discountLink: boolean;
  buyerLogin: boolean;
  customFields: boolean;
  productFilters: boolean;
  productVideo: boolean;
}

export const processResponse = (features: Record<string, never>): Features => {
  // const buyerLogin = false;
  const buyerLogin = features.buyer_login;
  return {
    id: features.id,
    user: features.user,
    themes: features.themes,
    customPages: features.custom_pages,
    customStoreLogo: features.custom_store_logo,
    referralDiscounts: features.referral_discounts,
    customMetaTags: features.custom_meta_tags,
    linkDomainToStore: features.link_domain_to_store,
    shippingLabelGenerationPickup: features.shipping_label_generation_pickup,
    bannerImages: features.banner_images,
    whiteLabel: features.white_label,
    noOfProducts: features.no_of_products,
    noOfProductImages: features.no_of_product_images,
    productAttributes: features.product_attributes,
    productOptions: features.product_options,
    advancedFilters: features.advanced_filters,
    wishList: features.wish_list && buyerLogin,
    productRating: features.product_rating,
    productReviewFb: features.product_review_fb,
    wholesalePricing: features.wholesale_pricing,
    orderBulkOperations: features.order_bulk_operations,
    crmBulkOperations: features.crm_bulk_operations,
    storePerformanceReport: features.store_performance_report,
    lowStockAlert: features.low_stock_alert,
    multiplePaymentMethods: features.multiple_payment_methods,
    internationalPayments: features.international_payments,
    multipleCurrencySupport: features.multiple_currency_support,
    shippingIntegration: features.shipping_integration,
    shippingSmsNotification: features.shipping_sms_notification,
    orderStatusSmsNotification: features.order_status_sms_notification,
    multipleShipmentPartners: features.multiple_shipment_partners,
    codPincodeRestriction: features.cod_pincode_restriction,
    cartValueBasedShipping: features.cart_value_based_shipping,
    pincodeCheck: features.pincode_check,
    marketingSmsNotification: features.marketing_sms_notification,
    promoCodeMarketing: features.promo_code_marketing,
    storeTestimonial: features.store_testimonial,
    marketingEmail: features.marketing_email,
    personalizedEmailAndSms: features.personalized_email_and_sms,
    emailAndSmsCampaign: features.email_and_sms_campaign,
    crmProfileTagging: features.crm_profile_tagging,
    autoTweet: features.auto_tweet,
    enduserPushNotification: features.enduser_push_notification,
    autoPostOnFb: features.auto_post_on_fb,
    posSalesSync: features.pos_sales_sync,
    facebookShop: features.facebook_shop,
    gstTaxation: features.gst_taxation,
    relatedProducts: features.related_products,
    recentlyViewed: features.recently_viewed,
    enduserAndroidApp: features.enduser_android_app,
    enduserIosApp: features.enduser_ios_app,
    imageEditor: features.image_editor,
    cataloguePdfGeneration: features.catalogue_pdf_generation,
    ssl: features.ssl,
    businessEmail: features.business_email,
    productSearch: features.product_search,
    edtOrder: features.edt_order,
    webmasterTools: features.webmaster_tools,
    weightBasedShippingCharges: features.weight_based_shipping_charges,
    customInvoicing: features.custom_invoicing,
    categoryTaxRules: features.category_tax_rules,
    regionalTaxRules: features.regional_tax_rules,
    premiumThemes: features.premium_themes,
    repeatOrder: features.repeat_order,
    marketing: features.marketing,
    marketingSms: features.marketing_sms,
    domainName: features.domain_name,
    bankTransfer: features.bank_transfer,
    ccavenue: features.ccavenue,
    udio: features.udio,
    razorpay: features.razorpay,
    hdfc: features.hdfc,
    payubiz: features.payubiz,
    axisbank: features.axisbank,
    paytm: features.paytm,
    facebookTwitterLikeWidget: features.facebook_twitter_like_widget,
    oneUp: features.oneUp,
    oneUpAdvanced: features.oneUp_advanced,
    onlineChat: features.online_chat,
    marketingAndPromotion: features.marketing_and_promotion,
    crm: features.crm,
    seo: features.seo,
    googleAdwords: features.google_adwords,
    editCss: features.edit_css,
    enduserFbRegistration: features.enduser_fb_registration,
    tdr: features.tdr,
    enduserApp: features.enduser_app,
    productReview: features.product_review,
    internationalStore: features.international_store,
    attributeBasedPricing: features.attribute_based_pricing,
    roleBasedLogin: features.role_based_login,
    numRoleBasedLogin: features.num_role_based_login,
    storeCredit: features.store_credit,
    enduserType: features.enduser_type,
    enduserTypeBasedPricing: features.enduser_type_based_pricing,
    abandonedCart: features.abandoned_cart,
    zohoCrm: features.zoho_crm,
    orderInvoiceZip: features.order_invoice_zip,
    bulkShipmentCreate: features.bulk_shipment_create,
    bulkShippingDetailsUpload: features.bulk_shipping_details_upload,
    storeUrlRedirection: features.store_url_redirection,
    buyAnywhereButton: features.buy_anywhere_button,
    customCourierPartner: features.custom_courier_partner,
    partialOrderAction: features.partial_order_action,
    customNavigation: features.custom_navigation,
    pageSpecificScripts: features.page_specific_scripts,
    basicSeo: features.basic_seo,
    contactUsPage: features.contact_us_page,
    socialMediaIntegration: features.social_media_integration,
    editContent: features.edit_content,
    inventoryBulkEdit: features.inventory_bulk_edit,
    inventoryBulkUpload: features.inventory_bulk_upload,
    categories: features.categories,
    productVariants: features.product_variants,
    productPaymentWebhooks: features.product_payment_webhooks,
    bulkPinCodeUpload: features.bulk_pin_code_upload,
    discountLink: features.discount_link,
    buyerLogin,
    customFields: features.custom_fields,
    productFilters: features.product_filters,
    productVideo: features.product_video,
  };
};

export default Features;
