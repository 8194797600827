// import { URLs } from '@common/api';
import { getData, URLs } from '@common/api';
import * as Text from '@common/models/Text';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchTextFailure, fetchTextRequest, fetchTextSuccess } from './slice';

export function* fetchTextSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const data = yield call(() => getData({ url: URLs.GET_TEXT_LAYOUT.replace('{id}', payload) }));
    const result = Text.processResponse(data.data);
    const { id, layout } = data?.data;
    yield put(fetchTextSuccess({ id: id, layout: layout, result }));
  } catch (e) {
    // console.log('ERROR', e);
    yield put(fetchTextFailure('Request failed with: ' + e));
  }
}

export default function* heroBannerSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchTextRequest.type, fetchTextSaga);
}
