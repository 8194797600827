import { getData, URLs } from '@common/api';
import * as Section from '@common/models/sections';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchCustomPageDetailFailure, fetchCustomPageDetailRequest, fetchCustomPageDetailSuccess } from './slice';

function* fetchCustomPageDetailSaga({ payload: customPageUrl }: PayloadAction<string>) {
  try {
    const { data } = yield call(() =>
      getData({ url: URLs.GET_CUSTOM_PAGE_DETAIL_VIA_URL, params: { url: customPageUrl } }),
    );
    data.sections = Section.processResponse(data.sections);
    yield put(fetchCustomPageDetailSuccess(data));
  } catch (e) {
    yield put(fetchCustomPageDetailFailure('Request failed with: ' + e));
  }
}

export default function* customPageDetailSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCustomPageDetailRequest.type, fetchCustomPageDetailSaga);
}
