import DEFAULT_CATEGORY_IMAGE from '../images/DefaultCategoryImage.png';
import { getLambdaLink, shouldUseCustomNavigation } from '../utils';

interface Category {
  id: number;
  name: string;
  slug: string;
  storeLink: string;
  homePageLambdaImage: string | null;
  image: string | null;
  additionalImage: string | null;
  label: string | null;
  isFeatured: boolean;
  parentCategory?: Category | null;
  order: number;
  childCategories?: Category[];
  description: string;
  pageTitle: string;
  created: string;
  modified: string;
}

export const getShopAllCategory = (childCategories: Category[]): Category => {
  const shopAllCategories = {
    id: 0,
    name: 'Shop All',
    storeLink: '/',
    homePageLambdaImage: null,
    slug: null,
    image: null,
    additionalImage: null,
    label: 'Shop All',
    isFeatured: false,
    parentCategory: null,
    order: 0,
    description: 'Shop All Description',
    pageTitle: 'ShopAll',
    created: '',
    modified: '',
  };
  return {
    ...shopAllCategories,
    childCategories: [
      ...childCategories,
      {
        ...shopAllCategories,
        childCategories: [],
        slug: 'shop',
        storeLink: '/shop?ordering=oos,final_price',
      },
    ],
  };
};

export const getFeaturedCategories = (category: Category, featuredCategories: Category[] = []): Category[] => {
  if (category.isFeatured) {
    featuredCategories.push(category);
  }
  category.childCategories.forEach((childCategory) => {
    getFeaturedCategories(childCategory, featuredCategories);
  });

  return featuredCategories;
};

export const processResponse = (category: Record<string, never>): Category => {
  if (category == null) {
    return null;
  }
  const parentCategory = processResponse(category.parent_category);
  const childCategories = (category.child_categories as Record<string, never>[])?.map((category) => {
    return processResponse(category);
  });

  let slug = (category.name as string)
    ?.replace(/[\W_]+/g, ' ')
    .toLowerCase()
    .replace(/  +/g, ' ')
    .replace(/%/g, '')
    .trim()
    .replace(/ /g, '-');
  if (!slug) {
    slug = (category.name as string)
      ?.replace(/\s+/g, ' ')
      .toLowerCase()
      .replace(/  +/g, ' ')
      .replace(/%/g, '')
      .trim()
      .replace(/ /g, '-');
  }
  const storeLink = shouldUseCustomNavigation() ? `/${slug}/c${category.id}` : `/category/${category.id}/${slug}`;

  const homePageLambdaImage = category.image
    ? getLambdaLink({
        link: category.image,
        width: 0,
        height: 0,
      })
    : DEFAULT_CATEGORY_IMAGE;
  return {
    id: category.id || category.value,
    name: category?.name || category.label,
    homePageLambdaImage,
    image: category.image || DEFAULT_CATEGORY_IMAGE,
    storeLink,
    slug,
    additionalImage: category.additional_image,
    label: category.label,
    isFeatured: category.is_featured,
    parentCategory,
    order: category.order,
    childCategories,
    description: category.description,
    pageTitle: category.page_title,
    created: category.created,
    modified: category.modified,
  };
};
export default Category;
