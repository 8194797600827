import { getData, URLs } from '@common/api';
import * as PageHeader from '@common/models/PageHeader';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import { fetchPageHeaderFailure, fetchPageHeaderRequest, fetchPageHeaderSuccess } from './slice';

export function* fetchPageHeaderSaga({ payload }: Record<string, string>): Generator<unknown> {
  try {
    const data = yield call(() => getData({ url: URLs.GET_PAGE_HEADER.replace('{userSectionId}', payload) }));
    const result = PageHeader.processResponse(data.data);
    const { id, layout } = data?.data;
    yield put(fetchPageHeaderSuccess({ id: id, layout: layout, result }));
  } catch (e) {
    // console.log('ERROR', e);
    yield put(fetchPageHeaderFailure('Request failed with: ' + e));
  }
}

export default function* pageHeaderSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchPageHeaderRequest.type, fetchPageHeaderSaga);
}
