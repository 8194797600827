import { ToastVariant } from '@common/../lib-components/Toast';
import * as CartProduct from '@common/models/CartProduct';
import NetworkCacheRow from '@common/models/NetworkCacheRow';
import { calculateSavings } from '@common/models/Product';
import ShippingAddress from '@common/models/ShippingAddress';
import Variant from '@common/models/Variant';
import { RootState, store } from '@common/store';
import { API_OBJECT_STORE, retrieveFromCache } from '@common/store/cache';
import { addToast } from '@common/store/toasts/slice';
import { addMinutes, isBrowser, isServer, loadFromLocalStorage } from '@common/utils';
import {
  CART_HASH_LOCAL_STORAGE_KEY,
  CART_LOCAL_STORAGE_KEY,
  context,
  getAccountId,
  getStoreUrl,
} from '@common/utils/constants';
import { isFormError } from '@common/utils/error';
import { getBuyerJWTToken, getLocalStorageItem } from '@common/utils/token';
import Axios, { AxiosAdapter, AxiosInstance, AxiosResponse } from 'axios';

import { getMerchantJWTToken } from '../utils/token';

let axios;

// A custom interface extending AxiosRequestConfig so that we can track
// startTime of every request
interface OurAxiosRequestConfig extends AxiosRequestConfig {
  startTime?: Date;
}

export const getAxiosInstance = (
  adapter: AxiosAdapter,
  user = 'buyer',
  requestUrl = '',
  requestId = new Date().getTime().toString(),
): AxiosInstance => {
  // const requestId = new Date().getTime();
  const axiosInstance = Axios.create({
    baseURL: isBrowser()
      ? context.BASE_MOJOCOMMERCE_CORE_URL
      : context.BASE_MOJOCOMMERCE_CORE_PRIVATE_URL || context.BASE_MOJOCOMMERCE_CORE_URL,
    withCredentials: context.WITH_CREDENTIALS,
    adapter,
  });

  axiosInstance.interceptors.request.use((req: OurAxiosRequestConfig) => {
    if (/sapi/i.test(req.url)) {
      req.headers['x-im-store-url'] = getStoreUrl();
      if (req.url.startsWith('/sapi/v2/')) {
        req.url = req.url.replace('{userId}', getAccountId());
      }
      const token = user === 'merchant' ? getMerchantJWTToken() : getBuyerJWTToken();
      if (token && !req.headers['Authorization']) {
        req.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    if (req.url.startsWith('/sapi/v2/')) {
      req.url = req.url.replace('{userId}', getAccountId());
    }
    req.startTime = new Date(); // Store start time of the request
    return req;
  });

  axiosInstance.interceptors.response.use(
    (res) => {
      const req = res.config as OurAxiosRequestConfig;
      const duration = new Date().getTime() - req.startTime.getTime();
      const url = axiosInstance.getUri(req);
      if (isServer()) {
        console.info(
          `[axios] ${requestId} ${requestUrl} ${res.status} ${req.method.toUpperCase()} ${url} ${duration}ms`,
        );
      }
      return res;
    },
    (err) => {
      // const duration = new Date().getTime() - req.startTime.getTime();
      const req = err.config as OurAxiosRequestConfig;
      const url = axiosInstance.getUri(req);
      const errorCode = err.code ? err.code : err.response ? err.response.status : 'UNKNOWN';
      if (isServer()) {
        // console.info(`[axios] ${errorCode} ${req.method.toUpperCase()} ${url} ${duration}ms`);
        return Promise.reject(`[REJECTED AXIOS] ${req.method.toUpperCase()} ${url} ${errorCode}`);
      } else {
        return Promise.reject(err);
      }
    },
  );

  return axiosInstance;
};

export const setAxiosInstance = (axiosInstance: AxiosInstance): void => {
  axios = axiosInstance;
};

export enum URLs {
  // Store APIs
  GET_STORE_INFO = '/sapi/v1/stores/',
  GET_SLIDER = '/sapi/v1/stores/slider/',
  GET_HOME = '/sapi/v1/stores/home/',
  GET_ABOUT = '/sapi/v1/stores/about/',
  GET_CONTACT = '/sapi/v1/portal/stores/contact/',
  POST_CONTACT = '/sapi/v1/portal/stores/contact/',
  GET_FAQ = '/sapi/v1/stores/faq/',
  POST_SECTION_CONTENT = '/sapi/v1/stores/storeinfos/',
  GET_POLICIES = '/sapi/v1/stores/policies/{policyType}/',
  GET_TESTIMONIALS = '/sapi/v1/stores/testimonials/',
  GET_CUSTOM_PAGES = '/sapi/v1/stores/custom-pages/',
  GET_CUSTOM_PAGE_DETAIL = '/sapi/v1/stores/custom-pages/{id}/',
  GET_CUSTOM_PAGE_DETAIL_VIA_URL = '/sapi/v1/stores/custom-pages/path/',
  GET_PAGE_SCRIPTS = '/sapi/v1/page-script/',
  GET_PAGE_SCRIPTS_STORE_PAGES = '/sapi/v1/page-script/store-pages/',
  GET_MDP_APP_SCRIPTS = '/sapi/v1/mdp-script/',
  GET_FBE_PIXEL = '/sapi/v1/facebook-business-extension',

  // Geo APIs
  GET_SUBDIVISIONS = '/sapi/v1/geo/subdivisions/',
  GET_COUNTRIES = '/sapi/v1/geo/countries/',
  GET_CITIES = '/sapi/v1/geo/cities/',

  // Product & Category APIs
  GET_PRODUCTS = '/sapi/v1/products/',
  GET_PRODUCT_DETAILS = '/sapi/v1/products/{id}/',
  GET_CATEGORIES = '/sapi/v1/categories/',
  GET_CATEGORY = '/sapi/v1/categories/{id}/',
  GET_CATEGORY_ATTRIBUTES = '/sapi/v1/attributes/{id}/',
  GET_CATEGORY_PRICE_LIMITS = '/sapi/v1/price-limits/{id}/',
  GET_PRODUCT_BUNDLE = '/sapi/v1/bundles/?location={pageLocation}&product_ids={id}',
  GET_PRODUCT_BUNDLE_CHECKOUT = '/sapi/v1/bundles/?location=cart&product_ids={id}',
  GET_PRODUCT_BUNDLE_THANKYOU_PAGE = '/sapi/v1/bundles/?location=thank_you',
  GET_SHOP_ALL_PRICE_LIMITS = '/sapi/v1/price-limits',

  // SEO APIs
  GET_METATAGS = '/sapi/v1/meta-tag/',
  GET_CUSTOM_METATAGS = '/sapi/v1/custom-meta-tag/',

  // Promotions APIs
  GET_PROMOTIONS = '/sapi/v1/promotions/',

  // Referrals APIs
  GET_REFERRALS = '/sapi/v1/users/referrals/',
  GET_REFERRAL_PROGRAM = '/sapi/v1/stores/referral-program/',
  GET_REFERRAL_CODE_DETAILS = '/sapi/v1/stores/referral-code/{referralCode}/',

  // Buyer APIs
  POST_LOGIN = '/sapi/v1/users/login/',
  POST_REGISTER = '/sapi/v1/users/register/',
  POST_EMAIL_ACTIVATE = '/sapi/v1/users/activate/',
  POST_LOG_OUT = '/sapi/v1/users/logout/',
  POST_GOOGLE_LOGIN = '/sapi/v1/users/social/google/',
  POST_FACEBOOK_LOGIN = '/sapi/v1/users/social/facebook/',
  DELETE_GOOGLE_LOGIN = '/sapi/v1/users/social/google/unlink/',
  DELETE_FACEBOOK_LOGIN = '/sapi/v1/users/social/facebook/unlink/',
  GET_USER_DETAILS = '/sapi/v1/users/profile/',
  POST_USER_DETAILS = '/sapi/v1/users/profile/',
  GET_USER_ADDRESSES = '/sapi/v1/users/addresses/',
  POST_USER_ADDRESS = '/sapi/v1/users/addresses/',
  PATCH_USER_ADDRESS = '/sapi/v1/users/addresses/{id}/',
  DELETE_USER_ADDRESS = '/sapi/v1/users/addresses/{id}/',
  GET_USER_ADDRESS = '/sapi/v1/users/addresses/{id}/',
  GET_USER_ORDERS = '/sapi/v1/users/orders/',
  GET_USER_ORDER = '/sapi/v1/users/orders/{id}/',
  GET_USER_ORDER_VIA_ORDER_ID = '/sapi/v1/users/orders/{id}/detail/',
  GET_SOCIAL_PROFILES = '/sapi/v1/users/social/profiles/',
  GET_CUSTOM_CSS = '/sapi/v1/custom-css/',
  POST_RESET_PASSWORD = '/sapi/v1/users/reset-password/',
  POST_CHANGE_PASSWORD = '/sapi/v1/users/change-password/',
  GET_DELETE_ACCOUNT = '/sapi/v1/users/delete-account/',
  POST_DELETE_ACCOUNT = '/sapi/v1/users/delete-account/',
  POST_USER_VALIDATE_SESSION = '/sapi/v1/users/validate-session/',
  GET_CUSTOM_THEME = '/sapi/v1/stores/theme-customisation/',
  //Reviews
  // GET_PRODUCT_REVIEWS = '/sapi/v1/users/orders',
  GET_PRODUCT_REVIEWS = '/sapi/v1/users/product-reviews/?product={id}&limit=10&page={page}',
  POST_PRODUCT_REVIEW = '/sapi/v1/users/product-reviews/',

  // Search API.
  GET_SEARCH = '/sapi/v1/products/suggest/',
  GET_SEARCH_NEW = '/sapi/v1/products/suggest/',

  // Save file
  POST_FILE = '/save-file/',
  POST_FILE_PRODUCT_OPTIONS = '/sapi/v1/upload-file/',

  PRIVATE_FILE_UPLOAD = '/sapi/v1/upload-private-file/',

  POST_PROMOTION = '/sapi/v1/promotions/validate/',

  GET_FEATURES = '/sapi/v1/features/',

  // Carts and Payments
  GET_CART = '/sapi/v1/carts/',
  POST_CART = '/sapi/v1/carts/',
  POST_WHOLESALE_DISCOUNT = '/sapi/v1/carts/wholesale-discount/',
  POST_CALCULATE_TAXES = '/sapi/v1/carts/calculate-taxes/',
  POST_MAKE_PAYMENT = '/sapi/v1/carts/placeorder/',
  POST_PLACE_ENQUIRY = '/sapi/v1/carts/place-enquiry/',
  POST_PAYMENT_STATUS = '/sapi/v1/carts/placeorder/payment-status/',
  GET_CONVENIENCE_FEE_STATUS = '/sapi/v1/carts/merchant-details/',
  DELETE_CART = '/sapi/v1/carts/{id}/',

  POST_VALIDATE_DISCOUNT_LINKS = '/sapi/v1/discount-links/validate/',
  // Onboard Status
  GET_ONBOARD_STATUS = '/sapi/v1/merchant-journey/steps/',
  POST_ONBOARD_APP_INTENT = '/sapi/v1/onboard/app-intent/',

  // Wishlist
  GET_WISHLIST = '/sapi/v1/users/wishlist/',
  POST_WISHLIST = '/sapi/v1/users/wishlist-action/',

  GET_NUDGES = '/sapi/v1/nudges/',
  GET_NAVBAR = '/sapi/v1/navbar-list/',

  //varifyOtp
  VERIFY_OTP = '/sapi/v1/carts/cod/verify-otp/',

  //resentOtp
  RESEND_OTP = '/sapi/v1/carts/cod/resend-otp/',
  POST_SUBSCRIBE = '/sapi/v1/store-newsletter-subscribe/',
  POST_UNSUBSCRIBE = '/sapi/v1/store-newsletter-unsubscribe/',

  //cancelOrder
  CANCEL_ORDER = 'sapi/v1/users/orders/request-cancel/',

  //sendOTP
  SEND_OTP_COD_ORDER_CANCELLATION = 'sapi/v1/users/orders/{id}/send-otp/',

  //resendOTP
  RESEND_OTP_COD_ORDER_CANCELLATION = 'sapi/v1/users/orders/resend-otp/',

  //report - abuse
  GET_REPORT_ABUSE_INFO = '/sapi/v1/report-abuse/status/',

  // auto fill google addresses suggestion
  GET_AUTO_FILL_ADDRESSES_PREDICTIONS = '/sapi/v1/autocomplete/fetch-perdictions/',
  GET_AUTO_FILL_ADDRESSES_DETAILS = '/sapi/v1/autocomplete/fetch-details/',

  // product attributes
  PRODUCT_FILTERS = 'sapi/v1/product-filters/',

  //whatsapp enabled
  GET_IS_WHATSAPP_ENABLED = 'sapi/v1/stores/whatsapp-workflow-enabled',

  //google oauth
  GET_GOOGLE_OAUTH_DATA = 'sapi/v1/google-oauth-data/',

  //terms and condition
  GET_TOS = '/sapi/v1/tos/status/',

  //builders
  GET_SECTIONS = '/sapi/v2/stores/sections/?limit=50',
  GET_USER_SECTION = `/sapi/v2/stores/{userId}/user-sections/?is_visible={section}&limit=500`,

  HERO_BANNER = `/sapi/v2/stores/{userId}/hero-banner/{userSectionId}/`,
  POST_HERO_BANNER = '/sapi/v2/stores/{userId}/hero-banner/',

  GET_PAGE_HEADER = `/sapi/v2/stores/{userId}/page-header/{userSectionId}/`,

  GET_ALL_PRODUCTS_LIST = '/sapi/v2/stores/{userId}/products/?base_product__isnull=1&ordering=oos,-created&page={queryParams}{filterQueryParams}',
  GET_DETAILED_SPECIFIC_PRODUCTS_LIST = '/sapi/v2/stores/{userId}/product-showcase/{userSectionId}/specific-products/?fields=id,title,price,images,final_price&limit=500',
  POST_PRODUCT_SHOWCASE = '/sapi/v2/stores/{userId}/product-showcase/',
  PATCH_PRODUCT_SHOWCASE = '/sapi/v2/stores/{userId}/product-showcase/{userSectionId}',
  GET_ALL_SEARCHED_PRODUCTS_LIST = '/sapi/v2/stores/{userId}/products/suggest/?fields=id,title,price,images,final_price&suggest={searchParams}&active=true&bponly=true&format=json&offset=0&limit=50',

  TEXT = '/sapi/v2/stores/{userId}/{text}/{userSectionId}/',
  POST_TEXT = '/sapi/v2/stores/{userId}/{text}/',
  PATCH_TEXT = '/sapi/v2/stores/{userId}/{text}/{userSectionId}/',
  PAGE_HEADER = `/sapi/v2/stores/{userId}/page-header/{userSectionId}/`,
  GET_SECTION_ID = `/sapi/v2/stores/layouts/?section={id}&limit=500`,

  DUPLICATE_SECTION = '/sapi/v2/stores/{userId}/{urlParam}/{userSectionId}/duplicate/',
  SECTION_VISIBILTY = '/sapi/v2/stores/{userId}/user-sections/{userSectionId}/',
  REORDER_SECTION = '/sapi/v2/stores/{userId}/user-sections/reorder/',

  //Media Manager
  MEDIA_API = `/sapi/v2/stores/{userId}/media-manager-image/`,
  RENAME_MEDIA_API = `/sapi/v2/stores/{userId}/media-manager-image/{mediaId}/`,

  CATEGORY_SHOWCASE = '/sapi/v2/stores/{userId}/category-showcase/{userSectionId}/',
  POST_CATEGORY_SHOWCASE = '/sapi/v2/stores/{userId}/category-showcase/',
  CATEGORY_SHOWCASE_LIST = '/sapi/v2/stores/{userId}/category-showcase/{userSectionId}/specific-categories/?fields=id,name,image,order&limit=500',
  ALL_CATEGORY = '/sapi/v2/stores/{userId}/categories/?fields=id,name,image,order,child_categories&parent_category__isnull=1&limit=500',
  FEATURED_CATEGORY = '/sapi/v2/stores/{userId}/categories/?fields=id,name,image,order,child_categories&is_featured=true&parent_category__isnull=1&limit=500',

  FETCH_TESTIMONIAL = `/sapi/v2/stores/{userId}/testimonial/{userSectionId}/`,
  POST_TESTIMONIAL = '/sapi/v2/stores/{userId}/testimonial/',
  PATCH_TESTIMONIAL = '/sapi/v2/stores/{userId}/testimonial/{userSectionId}/',

  STORE_UPDATES = '/sapi/v2/stores/{userId}/store-update/{userSectionId}/',
  POST_STORE_UPDATES = '/sapi/v2/stores/{userId}/store-update/',

  //layouts
  GET_USER_SECTIONS = '/sapi/v2/stores/{userId}/user-sections/',

  GET_HERO_BANNER_LAYOUT = '/sapi/v2/stores/{userId}/hero-banner/{id}/',
  GET_RICH_TEXT_EDITOR = '/sapi/v2/stores/{userId}/rich-text-editor/{id}/',
  GET_TEXT_LAYOUT = '/sapi/v2/stores/{userId}/text/{id}/',
  GET_IMAGE_AND_TEXT_LAYOUT = '/sapi/v2/stores/{userId}/image-text/{id}/',
  GET_PRODUCTSLIST = '/sapi/v2/stores/{userId}/product-showcase/{id}/products/?limit=24',
  GET_PRODUCTSLIST_SECTION = '/sapi/v2/stores/{userId}/product-showcase/{id}/',
  GET_HTML_BLOCK = '/sapi/v2/stores/{userId}/html-block/{id}/',
  GET_CATEGORIESLIST = '/sapi/v2/stores/{userId}/category-showcase/{id}/categories/?limit=24',
  GET_CATEGORIESLIST_SECTION = '/sapi/v2/stores/{userId}/category-showcase/{id}/',
  GET_CATEGORIES_PRODUCT_COUNT = '/sapi/v2/stores/{userId}/categories/product-count/',
  GET_STORES_FEATURES = `/sapi/v2/stores/{userId}/features/`,
  GET_STORE_UPDATE = '/sapi/v2/stores/{userId}/store-update/{id}/',
  GET_PRE_FILLED_DATA = '/sapi/v2/stores/prerender-section-content/',
  GET_CATEGORIES_PRODUCTS_LIST = `/sapi/v2/stores/{userId}/categories/products/?categories={categoriesIds}&limit=5&product_limit=4`,
  GET_PRODUCTS_RATING = `/sapi/v2/stores/{userId}/products/rating/?products={productIds}`,

  GET_DEFAULT_COLOR_PALETTE = `/sapi/v2/stores/{userId}/theme-color-palette/`,
  THEME_CUSTOMISATION = `/sapi/v2/stores/{userId}/theme-customisation/`,
  PATCH_THEME_CUSTOMISATION = `/sapi/v2/stores/{userId}/theme-customisation/{id}/`,
  GET_FONTS_DATA = `/sapi/v2/stores/fonts/?page={queryParams}{filterQueryParams}`,

  ALL_THEMES = '/sapi/v2/stores/{userId}/themes/?original_theme=True&limit=500&ordering=-created',
  THEME_BY_CATEGORY = '/sapi/v2/stores/{userId}/themes/?limit=500&category__slug={categoryName}&ordering=-created',
  THEME_CATEGORIES = '/sapi/v2/stores/theme-categories/?limit=50',
  ONBOARDING_MAPPED_THEME_CATEGORY = `/sapi/v2/stores/{userId}/onboarding-mapped-theme-category/`,
  POST_THEME_SELECTION = '/sapi/v2/stores/{userId}/themes/apply/',

  FETCH_CUSTOM_PAGE = `/sapi/v2/stores/{userId}/navbar-node/?limit=500`,
  CUSTOM_PAGE = `/sapi/v2/stores/{userId}/custom-page/{id}`,
  POST_CUSTOM_PAGE = `/sapi/v2/stores/{userId}/custom-page/`,
}

export interface GETRequestArgs {
  url: string;
  params?: unknown;
  headers?: unknown;
  useCache?: boolean;
  fetchAfterServe?: boolean;
  forceUpdate?: boolean;
}

export interface PostRequestArgs {
  url: string;
  data?: unknown;
  headers?: unknown;
}

export const getDataFromCache = (
  storeName: API_OBJECT_STORE,
  { url, params }: GETRequestArgs,
): Promise<NetworkCacheRow> => {
  const row = new NetworkCacheRow({ method: 'GET', url: url, params: params });

  return new Promise((resolve) => {
    retrieveFromCache(storeName, row.hash)
      .then((cached) => {
        const cachedTimestamp = new Date(cached?.timestamp);
        const cachedResponse = cached?.response;
        if (cachedResponse && new Date() < addMinutes(cachedTimestamp, 5)) {
          resolve(cached);
        } else {
          resolve(row);
        }
      })
      .catch(() => {
        resolve(row);
      });
  });
};

export const getData = ({
  url,
  params,
  headers,
  useCache,
  fetchAfterServe,
  forceUpdate,
}: GETRequestArgs): Promise<AxiosResponse> => {
  return axios({
    method: 'GET',
    url,
    params,
    headers,
    useCache,
    fetchAfterServe,
    forceUpdate,
  });
  // return new Promise((resolve, reject) => {
  //   // if (localStorage.getItem(url)) {
  //   //   resolve(JSON.parse(localStorage.getItem(url)));
  //   //   return;
  //   // }
  //   const pr = axios({
  //     method: 'GET',
  //     url,
  //     params,
  //     headers,
  //     useCache,
  //     fetchAfterServe,
  //     forceUpdate,
  //   });
  //   pr.then((data) => {
  //     // localStorage.setItem(url, JSON.stringify(data));
  //     resolve(data);
  //   }).catch((err) => {
  //     reject(err);
  //   });
  // });
};

export const postData = ({ url, data, headers }: PostRequestArgs): Promise<AxiosResponse> => {
  return axios({
    method: 'POST',
    url,
    data,
    headers,
  });
};

export const patchData = ({ url, data, headers }: PostRequestArgs): Promise<AxiosResponse> => {
  return axios({
    method: 'PATCH',
    url,
    data,
    headers,
  });
};

export const deleteData = ({ url, data, headers }: PostRequestArgs): Promise<AxiosResponse> => {
  return axios({
    method: 'DELETE',
    url,
    data,
    headers,
  });
};

export const saveFile = (requestData: FormData): Promise<AxiosResponse> => {
  const merchantToken = getMerchantJWTToken();
  return postData({
    url: URLs.POST_FILE,
    data: requestData,
    headers: {
      'Content-type': 'multipart/form-data',
      ...(merchantToken ? { Authorization: `Bearer ${merchantToken}` } : {}),
    },
  });
};

export const processUrl = (url: string): string => {
  if (process.env.NODE_ENV === 'production') {
    return `${window.location.origin}${url}`;
  }
  return url;
};

export const post = (url: string, formData: FormData): Promise<AxiosResponse> => {
  return axios.post(url, formData);
};
export const saveFileProductOptions = (requestData: FormData): Promise<AxiosResponse> => {
  const merchantToken = getMerchantJWTToken();
  return postData({
    url: URLs.PRIVATE_FILE_UPLOAD,
    data: requestData,
    headers: {
      'Content-type': 'multipart/form-data',
      ...(merchantToken ? { Authorization: `Bearer ${merchantToken}` } : {}),
    },
  });
};

export interface PostCartArgs {
  firstname: string;
  lastname: string;
  promoCode?: string;
  items: CartProduct.default[];
  email: string;
  contact: string;
  promoDiscountedTotal?: number;
  promoDiscount?: number;
  wsBreakup?: Record<string, unknown>;
  finalDiscountedPrice: number;
  address?: string;
  codCharge?: string;
}

export const postCart = ({
  firstname,
  lastname,
  promoCode,
  items,
  email,
  contact,
  promoDiscountedTotal,
  promoDiscount,
  wsBreakup,
  finalDiscountedPrice,
  address,
  codCharge,
}: PostCartArgs): Promise<AxiosResponse> => {
  const cartHash = getLocalStorageItem(CART_HASH_LOCAL_STORAGE_KEY);
  items = items.map((item) => ({ ...item, sku: item.sku ?? undefined }));
  if (!items.length && !cartHash) {
    return;
  }

  const totalPrice = items.reduce((sum, product) => sum + product.quantity * product.final_price, 0);
  const totalItems = items.reduce((sum, product) => sum + product.quantity, 0);
  const originalCartString = getLocalStorageItem(CART_LOCAL_STORAGE_KEY);
  const state: RootState = store.getState();
  const isNewTheme = state.storeInfo.storeInfo?.theme?.is_new;

  return postData({
    url: cartHash ? `${URLs.POST_CART}?cart_hash=${cartHash}` : URLs.POST_CART,
    data: {
      cart_json: {
        itemlist: items,
        promo: promoCode && {
          promo_code: promoCode,
          discount: promoDiscount,
        },
        total_items: totalItems,
        total_price: totalPrice,
        total_order: totalPrice,
        ws_breakup: wsBreakup ?? undefined,
        discounted_total_price: promoDiscountedTotal || -1,
        final_discounted_price: finalDiscountedPrice,
        original_cart: isNewTheme ? originalCartString : null,
        ...(codCharge && { cod_charge: codCharge }),
      },
      email: email,
      firstname,
      lastname,
      contact_number: contact,
      address: address,
      ...(!codCharge && { cart_type: 1 }),
    },
  });
};

interface ComputeDiscountForLinksArgs {
  discountCode: string;
  finalProduct: Variant;
  setFinalPrice: (number) => void;
  setSavings: (SavingsType) => void;
  setDiscountCode: (string) => void;
  setDefaults;
}

export const computeDiscountForLinks = async ({
  discountCode,
  finalProduct,
  setFinalPrice,
  setSavings,
  setDiscountCode,
  setDefaults,
}: ComputeDiscountForLinksArgs): Promise<void> => {
  try {
    const response = await postData({
      url: URLs.POST_VALIDATE_DISCOUNT_LINKS,
      data: {
        promo_code: discountCode,
        product: finalProduct?.id?.toString(),
      },
    });
    const { data } = response;
    const priceAfterDiscount = Math.max(finalProduct.finalPrice - data.flat_discount, 0);
    setFinalPrice(priceAfterDiscount);
    const computedSavings = calculateSavings(finalProduct.finalPrice, priceAfterDiscount);
    setSavings(computedSavings);
    setDiscountCode(discountCode);
    store.dispatch(
      addToast({
        content: 'Successfully applied discount code',
        variant: ToastVariant.success,
        dismissAfterMillis: 5000,
      }),
    );
  } catch (e) {
    if (isFormError(e)) {
      const { data } = e.response || {};
      if (data?.non_field_errors?.length) {
        store.dispatch(
          addToast({
            content: data?.non_field_errors?.[0],
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
      } else {
        store.dispatch(
          addToast({
            content: 'Invalid Discount Code',
            variant: ToastVariant.error,
            dismissAfterMillis: 5000,
          }),
        );
      }
    }
    setDefaults();
  }
};

export const postCartAddress = (address: ShippingAddress): Promise<AxiosResponse> => {
  const { cartId } = loadFromLocalStorage(CART_LOCAL_STORAGE_KEY);

  return postData({
    url: `${URLs.POST_CART}${cartId}/address/`,
    data: {
      ...address,
    },
  });
};
