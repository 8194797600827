import { getData, URLs } from '@common/api';
import * as Product from '@common/models/Product';
import * as ProductBundle from '@common/models/ProductBundle';
import * as Review from '@common/models/Review';
import { isBrowser } from '@common/utils';
import { viewOfferPage } from '@common/utils/mixpanel';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchNextPageProductReviewsFailed,
  fetchNextPageProductReviewsRequest,
  fetchNextPageProductReviewsSuccess,
  fetchProductBundleRequest,
  fetchProductBundleSuccess,
  fetchProductDetailsFailure,
  fetchProductDetailsRequest,
  fetchProductDetailsSuccess,
  fetchProductReviewsRequest,
  fetchProductReviewsSuccess,
  ProductBundlePayload,
} from './slice';

/* eslint-disable-next-line */
function* fetchProductDetailsSaga({ payload: productId }: PayloadAction<number>) {
  try {
    let { data } = yield call(() => getData({ url: URLs.GET_PRODUCT_DETAILS.replace('{id}', productId.toString()) }));
    data = Product.processResponse(data);
    if (isBrowser()) {
      viewOfferPage({ offer_id: data.slug, offer_category: data.type });
    }
    yield put(fetchProductDetailsSuccess(data));
  } catch (e) {
    // console.log('ERROR:' + e);
    yield put(fetchProductDetailsFailure('Request failed with: ' + e));
  }
}
/* eslint-disable-next-line */
function* fetchProductReviews({ payload: productId }: PayloadAction<number>) {
  try {
    let { data } = yield call(() =>
      getData({ url: URLs.GET_PRODUCT_REVIEWS.replace('{id}', productId.toString()).replace('{page}', '1') }),
    );
    const { count } = data;
    const next = Review.processPageNo(data.next);
    data = data.results?.map((review) => {
      return Review.processResponse(review);
    });
    yield put(fetchProductReviewsSuccess({ data, count, next }));
  } catch (e) {
    // yield put(fetchProductDetailsFailure('Request failed with: ' + e));
  }
}

function* fetchNextPageProductReviews({ payload: { nextReviewsUrlPageNo, productId } }) {
  try {
    let { data } = yield call(() =>
      getData({
        url: URLs.GET_PRODUCT_REVIEWS.replace('{id}', productId.toString()).replace(
          '{page}',
          nextReviewsUrlPageNo.toString(),
        ),
      }),
    );
    const next = Review.processPageNo(data.next);
    data = data.results?.map((review) => {
      return Review.processResponse(review);
    });
    yield put(fetchNextPageProductReviewsSuccess({ data, next }));
  } catch (e) {
    yield put(fetchNextPageProductReviewsFailed());
  }
}

function* fetchProductBundle({ payload: { productId, location } }: PayloadAction<ProductBundlePayload>) {
  const url = productId
    ? URLs.GET_PRODUCT_BUNDLE.replace('{pageLocation}', location).replace('{id}', productId)
    : URLs.GET_PRODUCT_BUNDLE_CHECKOUT;

  try {
    let { data } = yield call(() =>
      getData({
        url,
      }),
    );
    data = ProductBundle.processResponse(data);
    if (data.products) {
      data.products = data.products?.map((product) => {
        return Product.processResponse(product);
      });
    }

    yield put(fetchProductBundleSuccess(data));
  } catch (e) {}
}

export default function* productDetailsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchProductDetailsRequest.type, fetchProductDetailsSaga);
  yield takeEvery(fetchProductReviewsRequest.type, fetchProductReviews);
  yield takeEvery(fetchNextPageProductReviewsRequest.type, fetchNextPageProductReviews);
  yield takeEvery(fetchProductBundleRequest.type, fetchProductBundle);
}
