import { getData, URLs } from '@common/api';
import * as Category from '@common/models/Category';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  FetchCategoriesRequestPayloadType,
  fetchCategoryListFailure,
  fetchCategoryListRequest,
  fetchCategoryListSuccess,
} from './slice';

function* fetchCategoryListSaga({
  payload: { sectionId, useCache, fetchAfterServe },
}: PayloadAction<FetchCategoriesRequestPayloadType>) {
  try {
    const [{ data }, sectionData] = yield all([
      call(() =>
        getData({
          url: URLs.GET_CATEGORIESLIST.replace('{id}', sectionId),
          useCache,
          fetchAfterServe,
        }),
      ),
      call(() =>
        getData({
          url: URLs.GET_CATEGORIESLIST_SECTION.replace('{id}', sectionId),
          useCache,
          fetchAfterServe,
        }),
      ),
    ]);

    data.results = data.results?.map((category) => Category.processResponse(category));
    yield put(
      fetchCategoryListSuccess({
        sectionId,
        categories: data,
        title: sectionData?.data?.title,
        layout: sectionData?.data?.layout,
        showcaseType: sectionData?.data?.showcase_type,
      }),
    );
  } catch (e) {
    yield put(fetchCategoryListFailure({ sectionId, error: 'Request failed with: ' + e }));
  }
}

export default function* CategoryListSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchCategoryListRequest.type, fetchCategoryListSaga);
}
