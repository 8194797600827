import { getData, /*getDataFromCache,*/ URLs } from '@common/api';
import * as Product from '@common/models/Product';
import { productListLayoutdimensions } from '@common/models/Product';
import { isBrowser } from '@common/utils';
import { isMobile } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import { appendProductsQueryParamsToURL } from '@common/utils/params';
// import { insertToCache } from '@common/store/cache';
// import { API_OBJECT_STORE } from '@common/store/cache';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PRODUCTLIST_LAYOUT_SELECTION } from '@theme/utils/constants';
import { call, ForkEffect, put, takeEvery } from 'redux-saga/effects';

import {
  fetchProductsFailure,
  fetchProductsRequest,
  FetchProductsRequestPayloadType,
  fetchProductsSuccess,
} from './slice';

function* fetchProductsSaga({
  payload: { storeKey, queryParams, useCache, fetchAfterServe },
}: PayloadAction<FetchProductsRequestPayloadType>) {
  try {
    const url = URLs.GET_PRODUCTS;
    if ('ordering' in queryParams) {
      queryParams = { ...queryParams, ordering: `oos,${queryParams.ordering}` };
    }

    const { data } = yield call(() => getData({ url, params: queryParams, useCache, fetchAfterServe }));
    const { desktop, mobile } = productListLayoutdimensions[DEFAULT_PRODUCTLIST_LAYOUT_SELECTION] || {
      mobile: { width: 0, height: 0 },
      desktop: { width: 0, height: 0 },
    };
    data.results = data.results?.map((product) =>
      Product.processResponse(product, {
        productCardWidth: isMobile() ? mobile.width : desktop.width,
        productCardHeight: isMobile() ? mobile.height : desktop.height,
      }),
    );
    // row.response = data;
    yield put(fetchProductsSuccess({ storeKey, products: data, queryParams }));

    if ((storeKey === PRODUCTS_KEY.CATEGORY || storeKey === PRODUCTS_KEY.SHOP) && isBrowser()) {
      if (
        queryParams.facets ||
        queryParams.final_price__gte ||
        queryParams.final_price__lte ||
        queryParams.ordering !== 'oos,sort_order' ||
        queryParams.page !== 1
      ) {
        appendProductsQueryParamsToURL(queryParams);
      }
      // appendProductsQueryParamsToURL(queryParams);
    }
    // yield call(() => insertToCache(objectStoreName, row, true));
  } catch (e) {
    yield put(fetchProductsFailure({ storeKey, error: 'Request failed with: ' + e }));
  }
}

export default function* userDetailsSagas(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(fetchProductsRequest.type, fetchProductsSaga);
}
