import { combineReducers, configureStore, EnhancedStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware, { END, Saga, Task } from 'redux-saga';

import about from './about/slice';
import autoFillAddress from './autoFillAddresses/slice';
// import cache from './cache/slice';
import cart from './cart/slice';
import categories from './categories/slice';
import categoriesProductCount from './categoriesProductCount/slice';
import categoriesProductsList from './categoriesProductsList/slice';
import category from './category/slice';
import categoryAttributes from './categoryAttributes/slice';
import categoryList from './categoryList/slice';
import contact from './contact/slice';
import customCss from './customCss/slice';
import customPageDetail from './customPageDetail/slice';
import customPages from './customPages/slice';
import faq from './faq/slice';
import fbePixel from './fbePixel/slice';
import features from './features/slice';
import filterBar from './filterBar/slice';
import geo from './geo';
import global from './global/slice';
import googleOauth from './googleOauth/slice';
import heroBanner from './heroBanner/slice';
import home from './home/slice';
import htmlBlock from './htmlBlock/slice';
import imageAndText from './imageAndText/slice';
import legacySlider from './legacySlider/slice';
import mdpAppScripts from './mdpAppScripts/slice';
import newSearch from './newSearch/slice';
import nudges from './nudges/slice';
import onboardStatus from './onboardStatus/slice';
import pageHeader from './pageHeader/slice';
import pageScripts from './pageScripts/slice';
import policies from './policies/slice';
import policiesTos from './policiesTos/slice';
import priceLimits from './priceLimits/slice';
import productDetails from './productDetails/slice';
import productList from './productList/slice';
import productsRating from './productRating/slice';
import products from './products/slice';
import promotions from './promotions/slice';
import recentlyViewed from './recentlyViewed/slice';
import referral from './referral/slice';
import reportAbuse from './reportAbuse/slice';
import richTextEditor from './richTextEditor/slice';
import search from './search/slice';
import seo from './seo';
import shopAttributes from './shopAttributes/slice';
import slider from './slider/slice';
import socialProfiles from './socialProfiles/slice';
import storeInfo from './storeInfo/slice';
import storeTestimonials from './storeTestimonials/slice';
import testimonials from './testimonials/slice';
import text from './text/slice';
import themeCustomisation from './themeCustomisation/slice';
import toasts from './toasts/slice';
import userAddress from './userAddress/slice';
import userAddresses from './userAddresses/slice';
import userOrder from './userOrder/slice';
import userOrders from './userOrders/slice';
import userProfile from './userProfile/slice';
import userSections from './userSections/slice';
import whatsapp from './whatsapp/slice';
import wishlist from './wishList/slice';

const rootReducer = combineReducers({
  // cache,
  category,
  categories,
  userProfile,
  products,
  productDetails,
  home,
  global,
  faq,
  contact,
  categoryAttributes,
  about,
  cart,
  recentlyViewed,
  toasts,
  storeInfo,
  slider,
  policies,
  customPages,
  customPageDetail,
  promotions,
  seo,
  geo,
  userAddresses,
  userAddress,
  userOrder,
  userOrders,
  testimonials,
  customCss,
  themeCustomisation,
  socialProfiles,
  search,
  newSearch,
  features,
  pageScripts,
  onboardStatus,
  wishlist,
  nudges,
  mdpAppScripts,
  priceLimits,
  filterBar,
  referral,
  reportAbuse,
  fbePixel,
  autoFillAddress,
  shopAttributes,
  whatsapp,
  googleOauth,
  policiesTos,
  userSections,
  heroBanner,
  text,
  imageAndText,
  pageHeader,
  richTextEditor,
  productList,
  htmlBlock,
  categoryList,
  legacySlider,
  storeTestimonials,
  categoriesProductCount,
  categoriesProductsList,
  productsRating,
});

export type RootState = ReturnType<typeof rootReducer>;

interface MyEnhancedStore extends EnhancedStore {
  runSaga: <S extends Saga>(saga: S, ...args: Parameters<S>) => Task;
  close: () => END;
}

export const createStore = (preloadedState = {}): MyEnhancedStore => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];
  const isDevelopmentMode = process.env.NODE_ENV !== 'production';

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware,
    devTools: isDevelopmentMode,
  }) as MyEnhancedStore;

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
};

export let store: MyEnhancedStore = null;

export const setStore = (currentStore: MyEnhancedStore): void => {
  store = currentStore;
};
