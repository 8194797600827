import Invite from '@common/containers/Invite';
import Unsubscribe from '@common/containers/Unsubscribe';
import { MDPPageType } from '@common/models/MDPAppScript';
import { RootState } from '@common/store';
import { FeaturesSliceType } from '@common/store/features/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import Route from '@lib/Route';
import loadable from '@loadable/component';
import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

import { RouteMap } from './RouteMap';

const AboutUs = loadable(() => import(/* webpackChunkName: "AboutUs" */ '@Core/containers/AboutUs'));
const ProductDetail = loadable(() => import(/* webpackChunkName: "ProductDetail" */ '@containers/ProductDetail'));
const Policy = loadable(() => import(/* webpackChunkName: "Policy" */ '@Core/containers/Policy'));
const ShopAll = loadable(() => import(/* webpackChunkName: "ShopAll" */ '@containers/ShopAll'));
const Contact = loadable(() => import(/* webpackChunkName: "Contact" */ '@Core/containers/Contact'));
const Faq = loadable(() => import(/* webpackChunkName: "Faq" */ '@Core/containers/Faq'));
const Wishlist = loadable(() => import(/* webpackChunkName: "Wishlist" */ '@Core/containers/Wishlist'));
const ProfileDetails = loadable(() =>
  import(/* webpackChunkName: "ProfileDetails" */ '@Core/containers/ProfileDetails'),
);
import HomeLayout from '@Core/containers/HomeLayout';
const Cart = loadable(() => import(/* webpackChunkName: "Cart" */ '@containers/Cart'));
const Search = loadable(() => import(/* webpackChunkName: "Search" */ '@containers/Search'));
const Category = loadable(() => import(/* webpackChunkName: "Category" */ '@containers/Category'));
const ProductReview = loadable(() => import(/* webpackChunkName: "ProductReview" */ '@Core/containers/ProductReview'));
const AddEditAddress = loadable(() =>
  import(/* webpackChunkName: "AddEditAddress" */ '@Core/containers/AddEditAddress'),
);
const Addresses = loadable(() => import(/* webpackChunkName: "Addresses" */ '@Core/containers/Addresses'));
const Captcha = loadable(() => import(/* webpackChunkName: "Captcha" */ '@Core/containers/Captcha'));
const CustomPage = loadable(() => import(/* webpackChunkName: "CustomPage" */ '@Core/containers/CustomPage'));
const EmailConfirmation = loadable(() =>
  import(/* webpackChunkName: "EmailConfirmation" */ '@Core/containers/EmailConfirmation'),
);
const ForgotPassword = loadable(() =>
  import(/* webpackChunkName: "ForgotPassword" */ '@Core/containers/ForgotPassword'),
);
const Login = loadable(() => import(/* webpackChunkName: "Login" */ '@Core/containers/Login'));
const NeedHelp = loadable(() => import(/* webpackChunkName: "NeedHelp" */ '@Core/containers/NeedHelp'));
const OrderDetail = loadable(() => import(/* webpackChunkName: "OrderDetail" */ '@Core/containers/OrderDetail'));
const Orders = loadable(() => import(/* webpackChunkName: "Orders" */ '@Core/containers/Orders'));
const Referral = loadable(() => import(/* webpackChunkName: "Referral" */ '@Core/containers/Referral'));
const Register = loadable(() => import(/* webpackChunkName: "Register" */ '@Core/containers/Register'));
const Social = loadable(() => import(/* webpackChunkName: "Social" */ '@Core/containers/Social'));
const TrackOrder = loadable(() => import(/* webpackChunkName: "TrackOrder" */ '@Core/containers/TrackOrder'));
const TrackOrderDetails = loadable(() =>
  import(/* webpackChunkName: "TrackOrderDetails" */ '@Core/containers/TrackOrderDetails'),
);

import MDPInjectScript from '@lib/MDPInjectScript';

interface RoutesProps {
  features: FeaturesSliceType;
  storeInfo: StoreInfoSliceType;
}

const Routes: React.FC<RoutesProps> = ({ features, storeInfo }) => {
  return (
    <>
      <Switch>
        <Route exact path={'/'} component={HomeLayout} />
        <Route exact path={'/home'} component={HomeLayout} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/search/" component={Search} />
        <Route exact path="/category/:categoryId([0-9]+)/:categorySlug" component={Category} />
        <Route exact path="/category/:categoryId([0-9]+)/:categorySlug([\w-]+)/search" component={Category} />
        <Route exact path={RouteMap.ABOUT} component={AboutUs} />
        {features.features?.contactUsPage ? <Route exact path={RouteMap.CONTACT} component={Contact} /> : null}
        <Route exact path={RouteMap.FAQ} component={Faq} />
        <Route exact isProtected path={RouteMap.ACCOUNT_ORDERS} component={Orders} />
        <Route exact isProtected path={RouteMap.ACCOUNT_ORDER_DETAIL} component={OrderDetail} />
        <Route exact isProtected path={RouteMap.ACCOUNT_PROFILE} component={ProfileDetails} />
        {storeInfo.storeInfo?.isReferralEnabled ? (
          <Route exact isProtected path={RouteMap.ACCOUNT_INVITE} component={Referral} />
        ) : null}
        <Route exact isProtected path={RouteMap.ACCOUNT_HELP} component={NeedHelp} />
        <Route exact isProtected path={RouteMap.ACCOUNT_ADDRESSES} component={Addresses} />
        <Route exact isProtected path={RouteMap.ACCOUNT_ADDRESS_ADD} component={AddEditAddress} />
        <Route exact isProtected path={RouteMap.ACCOUNT_ADDRESS_EDIT} component={AddEditAddress} />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          isProtected
          path={RouteMap.PRODUCT_REVIEW}
          component={ProductReview}
        />
        <Route exact path={RouteMap.PRODUCT_1} component={ProductDetail} />
        <Route exact path={RouteMap.PRODUCT_2} component={ProductDetail} />
        <Route exact path={RouteMap.PRODUCT_3} component={ProductDetail} />
        <Route exact isDisabled={!features?.features?.buyerLogin} path={RouteMap.ACCOUNT_LOGIN} component={Login} />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_FORGOT_PASSWORD}
          component={ForgotPassword}
        />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_REGISTER}
          component={Register}
        />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_LOGIN_GOOGLE}
          component={Social}
        />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_LOGIN_FACEBOOK}
          component={Social}
        />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_REGISTER_GOOGLE}
          component={Social}
        />
        <Route
          exact
          isDisabled={!features?.features?.buyerLogin}
          path={RouteMap.ACCOUNT_REGISTER_FACEBOOK}
          component={Social}
        />
        {features.features?.wishList ? (
          <Route exact isProtected path={RouteMap.ACCOUNT_WISHLIST} component={Wishlist} />
        ) : null}
        <Route exact path={RouteMap.ACCOUNT_EMAIL_ACTIVATION} component={EmailConfirmation} />
        <Route exact path={RouteMap.POLICY} component={Policy} />
        <Route exact path={RouteMap.SEO_POLICY_TERMS_AND_CONDITIONS} component={Policy} />
        <Route exact path={RouteMap.SEO_POLICY_CANCELLATION_AND_REFUND} component={Policy} />
        <Route exact path={RouteMap.SEO_POLICY_PRIVACY} component={Policy} />
        <Route exact path={RouteMap.SEO_POLICY_SHIPPING_AND_DELIVERY} component={Policy} />
        <Route exact path={RouteMap.CAPTCHA} component={Captcha} />
        <Route exact path={RouteMap.TRACK_ORDER} component={TrackOrder} />
        <Route exact path={RouteMap.TRACK_ORDER_STATUS} component={TrackOrder} />
        <Route exact path={RouteMap.TRACK_ORDER_DETAILS} component={TrackOrderDetails} />
        <Route exact path={RouteMap.SHOP_ALL} component={ShopAll} />
        <Route exact path={RouteMap.REFERRAL_PROGRAM} component={Invite} />
        <Route path={RouteMap.UNSUBSCRIBE} component={Unsubscribe} />
        <Route path={RouteMap.CUSTOM_PAGE_2} component={CustomPage} />
      </Switch>
      <MDPInjectScript pageType={MDPPageType.STORE_WIDE} />
    </>
  );
};

export default connect(({ features, storeInfo }: RootState) => ({ features, storeInfo }))(Routes);
