import { getFilteredSliderImages } from '@common/models/Slider';
import { SliderSliceType } from '@common/store/slider/slice';
import React from 'react';

export interface sliderMainProps {
  className: string;
  id: string;
  slider: SliderSliceType;
}

const SliderMain: React.FC<sliderMainProps> = ({ slider, children, ...props }) => {
  const filteredImages = getFilteredSliderImages({ slider, shouldImageBePresent: true });

  return filteredImages?.length ? <div {...props}>{children}</div> : null;
};

export default SliderMain;
