import { getAxiosInstance, setAxiosInstance } from '@common/api';
import PostMessage from '@common/components/PostMessage';
import { fetchCustomPagesRequest } from '@common/store/customPages/slice';
// const tailwindConfig = require('@theme/tailwind.config.js');
import { FBEPixelSliceType, fetchFBEPixelRequest } from '@common/store/fbePixel/slice';
import { fetchNavigationDetailsRequest } from '@common/store/filterBar/slice';
import { fetchCountriesRequest } from '@common/store/geo/countries/slice';
import { fetchGoogleOauthDataRequest, GoogleOAuthSliceType } from '@common/store/googleOauth/slice';
import { fetchOnboardStatusRequest } from '@common/store/onboardStatus/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { fetchTosRequest, PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  resetProductsStore,
} from '@common/store/products/slice';
import { fetchPromotionsRequest } from '@common/store/promotions/slice';
import { fetchCustomMetaTagsRequest } from '@common/store/seo/customMetaTags/slice';
import { fetchMetaTagsRequest } from '@common/store/seo/metaTags/slice';
import { fetchSliderRequest } from '@common/store/slider/slice';
import { fetchStoreInfoRequest, StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { fetchTestimonialsRequest } from '@common/store/testimonials/slice';
import { fetchUserProfileRequest } from '@common/store/userProfile/slice';
import { fetchUserSectionsRequest, UserSectionsSliceType } from '@common/store/userSections/slice';
import { fetchWishListRequest, WishListSliceType } from '@common/store/wishList/slice';
import { getCustomMetaTagsParams, getPixelScript, getTrailingSlashUrl, loadFromLocalStorage } from '@common/utils';
import { trackEvent } from '@common/utils/analytics';
import {
  CART_LOCAL_STORAGE_BACKUP_KEY,
  CART_LOCAL_STORAGE_KEY,
  getStoreUrl,
  PRODUCTS_KEY,
  setAccountId,
} from '@common/utils/constants';
import { initializeErrorTracking } from '@common/utils/error';
import { saveResellerToCart } from '@common/utils/reseller';
import { AxiosAdapter } from 'axios';
import InnerHTML from 'dangerously-set-html-content';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import InjectScript from '../../../lib-components/InjectScript';
import { RootState } from '../../store';
import { CartSliceType, replaceCart } from '../../store/cart/slice';
import { CustomCssSliceType, fetchCustomCssRequest } from '../../store/customCss/slice';
import { CustomPagesSliceType } from '../../store/customPages/slice';
import { FeaturesSliceType, fetchFeaturesRequest } from '../../store/features/slice';
import { FilterBarSliceType } from '../../store/filterBar/slice';
import { CountriesSliceType } from '../../store/geo/countries/slice';
import { toggleEditMode } from '../../store/global/slice';
import { fetchHomeRequest, HomeSliceType } from '../../store/home/slice';
import { fetchMDPAppScriptsRequest, MDPAppScriptsSliceType } from '../../store/mdpAppScripts/slice';
import {
  fetchPageScriptsRequest,
  getPageScriptsQueryParamsInitialState,
  PageScriptsSliceType,
} from '../../store/pageScripts/slice';
import { ProductsSliceType } from '../../store/products/slice';
import { PromotionsSliceType } from '../../store/promotions/slice';
import { fetchReportAbuseRequest } from '../../store/reportAbuse/slice';
import { CustomMetaTagsSliceType } from '../../store/seo/customMetaTags/slice';
import { MetaTagsSliceType } from '../../store/seo/metaTags/slice';
// import { SliderSliceType } from '../../store/slider/slice';
import { TestimonialsSliceType } from '../../store/testimonials/slice';
import { fetchThemeCustomisationRequest, ThemeCustomisationSliceType } from '../../store/themeCustomisation/slice';
import { isBrowser } from '../../utils';
import { handleSocialLoginResponse, SOCIAL_NETWORK } from '../../utils/auth';
import { Context, COOKIE_KEYS, setContext } from '../../utils/constants';
import { getBuyerJWTToken, removeCookie, removeLocalStorageItem } from '../../utils/token';
import ThemeCustomisationComp from '../ThemeCustomisation';

interface StartupProps {
  adapter?: AxiosAdapter;
  context?: Context;
  children: React.ReactNode;
  storeInfo: StoreInfoSliceType;
  metaTags: MetaTagsSliceType;
  customPages: CustomPagesSliceType;
  filterBar: FilterBarSliceType;
  customMetaTags: CustomMetaTagsSliceType;
  fetchStoreInfoRequest: typeof fetchStoreInfoRequest;
  fetchUserProfileRequest: typeof fetchUserProfileRequest;
  resetProductsStore: typeof resetProductsStore;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchSliderRequest: typeof fetchSliderRequest;
  fetchCustomPagesRequest: typeof fetchCustomPagesRequest;
  fetchNavigationDetailsRequest: typeof fetchNavigationDetailsRequest;
  fetchCountriesRequest: typeof fetchCountriesRequest;
  fetchPromotionsRequest: typeof fetchPromotionsRequest;
  fetchCustomMetaTagsRequest: typeof fetchCustomMetaTagsRequest;
  fetchMetaTagsRequest: typeof fetchMetaTagsRequest;
  fetchTestimonialsRequest: typeof fetchTestimonialsRequest;
  fetchHomeRequest: typeof fetchHomeRequest;
  fetchFeaturesRequest: typeof fetchFeaturesRequest;
  fetchPageScriptsRequest: typeof fetchPageScriptsRequest;
  replaceCart: typeof replaceCart;
  toggleEditMode: typeof toggleEditMode;
  products: Record<string, ProductsSliceType>;
  home: HomeSliceType;
  // slider: SliderSliceType;
  testimonials: TestimonialsSliceType;
  countries: CountriesSliceType;
  promotions: PromotionsSliceType;
  features: FeaturesSliceType;
  pageScripts: PageScriptsSliceType;
  customCss: CustomCssSliceType;
  themeCustomisation: ThemeCustomisationSliceType;
  fetchThemeCustomisationRequest: typeof fetchThemeCustomisationRequest;
  fetchCustomCssRequest: typeof fetchCustomCssRequest;
  fetchOnboardStatusRequest: typeof fetchOnboardStatusRequest;
  fetchMDPAppScriptsRequest: typeof fetchMDPAppScriptsRequest;
  mdpAppScripts: MDPAppScriptsSliceType;
  fetchReportAbuseRequest: typeof fetchReportAbuseRequest;
  fbePixel: FBEPixelSliceType;
  fetchFBEPixelRequest: typeof fetchFBEPixelRequest;
  fetchGoogleOauthDataRequest: typeof fetchGoogleOauthDataRequest;
  googleOauth: GoogleOAuthSliceType;
  fetchTosRequest: typeof fetchTosRequest;
  policiesTos: PoliciesSliceTosType;
  url: string;
  userSections: UserSectionsSliceType;
  fetchUserSectionsRequest: typeof fetchUserSectionsRequest;
  pageHeader: PageHeaderSliceType;
  wishlist: WishListSliceType;
  fetchWishListRequest: typeof fetchWishListRequest;
  requestId: string;
}

class Startup extends React.Component<StartupProps> {
  constructor(props) {
    super(props);
    this.state = { shouldRender: 1 };
    setContext(this.props.context);
    setAccountId(this.props.storeInfo?.storeInfo?.gmasAccountId);
    setAxiosInstance(getAxiosInstance(this.props.adapter, 'buyer', this.props.url, this.props.requestId));
    initializeErrorTracking();
    if (!this.props.home.isFetched) {
      this.props.fetchHomeRequest();
    }
    if (!this.props.userSections.isFetched) {
      this.props.fetchUserSectionsRequest();
    }
    // if (!this.props.slider.isFetched) {
    //   this.props.fetchSliderRequest();
    // }
    if (!this.props.customPages.isFetched) {
      this.props.fetchCustomPagesRequest();
    }
    if (!this.props.customMetaTags.isFetched) {
      const pathname = new URL(isBrowser() ? window.location.href : this.props.url).pathname;
      const customMetaTagsParams = getCustomMetaTagsParams(pathname);
      this.props.fetchCustomMetaTagsRequest(customMetaTagsParams);
    }
    if (!this.props.metaTags.isFetched) {
      this.props.fetchMetaTagsRequest();
    }
    if (!this.props.features.isFetched) {
      this.props.fetchFeaturesRequest();
    }
    if (!this.props.themeCustomisation.isFetched) {
      this.props.fetchThemeCustomisationRequest();
    }
    if (!this.props.fbePixel.isFetched) {
      this.props.fetchFBEPixelRequest();
    }
    if (!this.props.googleOauth.isFetched) {
      this.props.fetchGoogleOauthDataRequest();
    }
    if (!this.props.customCss.isFetched) {
      this.props.fetchCustomCssRequest();
    }
    getBuyerJWTToken() && this.props.fetchUserProfileRequest();
  }

  componentDidMount() {
    this.props.fetchStoreInfoRequest();
    this.props.fetchReportAbuseRequest();
    if (!this.props.products[PRODUCTS_KEY.FEATURED]?.isFetched) {
      this.fetchFeaturedProducts();
    }
    if (!this.props.testimonials.isFetched) {
      this.props.fetchTestimonialsRequest();
    }
    if (!this.props.products[PRODUCTS_KEY.LATEST]?.isFetched) {
      this.fetchLatestProducts();
    }
    if (!this.props.countries.isFetched) {
      this.props.fetchCountriesRequest();
    }
    if (!this.props.promotions.isFetched) {
      this.props.fetchPromotionsRequest();
    }
    if (!this.props.pageScripts.isFetched) {
      this.props.fetchPageScriptsRequest({
        queryParams: getPageScriptsQueryParamsInitialState(),
      });
    }
    if (!this.props.mdpAppScripts.isFetched) {
      this.props.fetchMDPAppScriptsRequest();
    }

    if (!this.props.filterBar.isFetched) {
      this.props.fetchNavigationDetailsRequest();
    }

    if (!this.props.policiesTos.isFetched) {
      this.props.fetchTosRequest();
    }
    if (!this.props.wishlist.isFetched && getBuyerJWTToken()) {
      this.props.fetchWishListRequest({
        queryParams: {
          limit: 50,
          offset: 0,
          page: 1,
        },
      });
    }

    if (isBrowser()) {
      const cart = (loadFromLocalStorage(CART_LOCAL_STORAGE_KEY) as unknown) as CartSliceType;
      const backupCart = (loadFromLocalStorage(CART_LOCAL_STORAGE_BACKUP_KEY) as unknown) as CartSliceType;

      if (backupCart) {
        removeLocalStorageItem(CART_LOCAL_STORAGE_KEY);
        backupCart.groupToResellerMapping = cart?.groupToResellerMapping;
        this.props.replaceCart(backupCart);
      } else if (cart) {
        this.props.replaceCart(cart);
      }
      removeLocalStorageItem(CART_LOCAL_STORAGE_BACKUP_KEY);
      removeCookie(COOKIE_KEYS.IS_CART_ORDER);
      removeCookie(COOKIE_KEYS.CART_PRODUCT_TYPE);

      const queryDict = new URLSearchParams(window.location.search);

      const socialAccessToken = queryDict.get('social_access_token');
      const provider = queryDict.get('provider');

      if (socialAccessToken && provider) {
        if (provider === 'google') {
          handleSocialLoginResponse(socialAccessToken, SOCIAL_NETWORK.GOOGLE);
        } else if (provider === 'facebook') {
          handleSocialLoginResponse(socialAccessToken, SOCIAL_NETWORK.FACEBOOK);
        }
      }

      saveResellerToCart();
    }

    if (this.props.fbePixel.pixel?.pixelId) {
      trackEvent('Fired Pixel Event', {
        event_name: 'PageView',
      });
    }
  }

  fetchFeaturedProducts() {
    this.props.resetProductsStore(PRODUCTS_KEY.FEATURED);
    this.props.fetchProductsRequest({
      storeKey: PRODUCTS_KEY.FEATURED,
      queryParams: {
        ...getProductQueryParamsInitialState(),
        is_featured: true,
        ordering: '-created',
      },
    });
  }

  fetchLatestProducts() {
    this.props.resetProductsStore(PRODUCTS_KEY.LATEST);
    this.props.fetchProductsRequest({
      storeKey: PRODUCTS_KEY.LATEST,
      queryParams: {
        ...getProductQueryParamsInitialState(),
        ordering: '-created',
      },
    });
  }
  handleShouldRender = () => {
    this.setState(
      {
        shouldRender: 0,
      },
      () => {
        setTimeout(() => {
          this.setState({
            shouldRender: 1,
          });
        }, 0);
      },
    );
  };
  render() {
    const id = this.props.userSections?.userSections[0]?.id;
    return (
      <>
        <Helmet>
          <link
            rel="shortcut icon"
            href={this.props.storeInfo.storeInfo?.favicon || 'data:;base64,='}
            type="image/x-icon"
          />
          <link rel="apple-touch-icon" href={this.props.storeInfo.storeInfo?.favicon || 'data:;base64,='} />
          {/* <meta name="theme-color" content={tailwindConfig?.theme?.colors?.theme} /> */}
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=0" />
        </Helmet>
        {this.props.googleOauth.isFetched && !this.props.googleOauth.error ? (
          <Helmet>
            {this.props.googleOauth.googleOauth?.results?.map((result) => (
              <meta key={result.id} name={result.metaName} content={result.metaContent} />
            ))}
          </Helmet>
        ) : null}
        {this.props.metaTags.isFetched && !this.props.metaTags.error ? (
          <Helmet>
            <meta charSet={this.props.metaTags.metaTags.contentType} />
            <title>
              {this.props.storeInfo.storeInfo?.isSEOEnabled
                ? this.props.metaTags.metaTags.title
                : this.props.storeInfo.storeInfo?.storename}
            </title>
            <meta property="og:title" content={this.props.metaTags.metaTags.title} />
            {this.props.pageHeader[id]?.pageHeader?.logo && (
              <meta itemProp="image" property="og:image" content={this.props.pageHeader[id]?.pageHeader?.logo} />
            )}
            <meta property="og:type" content="website" />
            <meta
              property="og:url"
              content={`${getStoreUrl().slice(0, -1)}${getTrailingSlashUrl(
                new URL(isBrowser() ? window.location.href : this.props.url).pathname,
              )}`}
            />
          </Helmet>
        ) : null}
        {/* <Helmet>
          <link rel="canonical" href={this.props.context?.URL} />
        </Helmet> */}
        {/* {this.props.customMetaTags.isFetched &&
        !this.props.customMetaTags.error &&
        this.props.features.features?.customMetaTags ? (
          <Helmet>
            {this.props.customMetaTags.customMetaTags?.results?.map((result, index) => (
              <meta key={index} name={result.name} content={result.content} />
            ))}
          </Helmet>
        ) : null} */}
        <PostMessage handleToRender={this.handleShouldRender} />
        {isBrowser() && !window.location.pathname?.includes('/captcha') ? (
          <InjectScript pageTypeVerbose="Store Wide" />
        ) : null}
        {this.props.features.features?.editCss ? (
          // TODO: Find better solutioning
          <div dangerouslySetInnerHTML={{ __html: `<style>${this.props.customCss.customCss || ''}  </style>` }} />
        ) : null}
        {this.props.themeCustomisation?.themeCustomisation ? (
          <ThemeCustomisationComp customTheme={this.props.themeCustomisation?.themeCustomisation} />
        ) : null}
        {this.props.fbePixel.pixel?.pixelId ? (
          <InnerHTML html={getPixelScript(this.props.fbePixel.pixel.pixelId)} />
        ) : null}
        {this.state.shouldRender === 1 && <>{this.props.children}</>}
      </>
    );
  }
}

export default connect(
  ({
    products,
    home,
    // slider,
    testimonials,
    geo: { countries },
    promotions,
    storeInfo,
    seo: { metaTags, customMetaTags },
    customPages,
    filterBar,
    features,
    pageScripts,
    customCss,
    mdpAppScripts,
    themeCustomisation,
    fbePixel,
    googleOauth,
    policiesTos,
    userSections,
    pageHeader,
    wishlist,
  }: RootState) => ({
    features,
    products,
    home,
    // slider,
    countries,
    promotions,
    testimonials,
    storeInfo,
    metaTags,
    customMetaTags,
    customPages,
    filterBar,
    pageScripts,
    customCss,
    themeCustomisation,
    mdpAppScripts,
    fbePixel,
    googleOauth,
    policiesTos,
    userSections,
    pageHeader,
    wishlist,
  }),
  {
    resetProductsStore,
    fetchProductsRequest,
    // fetchSliderRequest,
    fetchCustomPagesRequest,
    fetchNavigationDetailsRequest,
    fetchCountriesRequest,
    fetchPromotionsRequest,
    fetchCustomMetaTagsRequest,
    fetchMetaTagsRequest,
    fetchStoreInfoRequest,
    fetchTestimonialsRequest,
    fetchUserProfileRequest,
    fetchHomeRequest,
    fetchFeaturesRequest,
    fetchPageScriptsRequest,
    replaceCart,
    toggleEditMode,
    fetchCustomCssRequest,
    fetchThemeCustomisationRequest,
    fetchOnboardStatusRequest,
    fetchMDPAppScriptsRequest,
    fetchReportAbuseRequest,
    fetchFBEPixelRequest,
    fetchGoogleOauthDataRequest,
    fetchTosRequest,
    fetchUserSectionsRequest,
    fetchWishListRequest,
  },
)(Startup);
